const bitcoinCashLib = require("@bitcoin-dot-com/bitcoincashjs2-lib");
const {Transaction} = bitcoinCashLib;

module.exports = {
  getTransactionHistory,
  getTransaction,
  getUtxos,
  broadcastTransaction
}

async function getTransactionHistory(electrum, txHash) {
  await electrum.ready();
  const transactions = await electrum.request("blockchain.scripthash.get_history", txHash);
  if (transactions instanceof Error) {
    throw transactions
  }
  
  return transactions.map((tx) => ({
    txHash: tx.tx_hash,
    height: tx.height
  }))
  //Sort from newest to oldest
  .sort((a, b) => b.height - a.height)
  //Only need txHashes
  .map(tx => tx.txHash);
}

async function getTransaction(electrum, txHash) {
  await electrum.ready();
  const transactionHex = await electrum.request("blockchain.transaction.get", txHash, false);

  if (transactionHex instanceof Error) {
    throw transactionHex
  }

  const transaction = Transaction.fromHex(transactionHex);

  return transaction;
}

async function getUtxos(electrum, scriptHash) {
  await electrum.ready();
  const unspentUTXOs = await electrum.request("blockchain.scripthash.listunspent", scriptHash);

  if (unspentUTXOs instanceof Error) {
    throw unspentUTXOs;
  }

  return unspentUTXOs.map((utxo) => ({
    txHash: utxo.tx_hash,
    txIndex: utxo.tx_pos,
    satoshis: utxo.value,
    height: utxo.height,
  }));
}

async function broadcastTransaction(electrum, txHex) {
  await electrum.ready();
  const rawTxHex = typeof txHex === 'string' ? txHex : txHex.toString('hex');

  const broadcastResult = await electrum.request(
    "blockchain.transaction.broadcast",
    rawTxHex
  );

  if (broadcastResult instanceof Error) {
    throw broadcastResult
  }

  return broadcastResult;
}