import { useState, useEffect, useContext } from "preact/hooks"
import { createContext } from 'preact/compat';

export const ElectrumContext = createContext(null);
export const useElectrum = () => useContext(ElectrumContext);

export function WithElectrum(children) {
  return ElectrumProvider({ children });
};

export function ElectrumProvider({ children, electrum }) {

  return <ElectrumContext.Provider value={electrum}>  
    {children}
  </ElectrumContext.Provider>
}