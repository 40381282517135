const {BITBOX} = require("bitbox-sdk");
const bitbox = new BITBOX();
const { CID } = require('ipfs');

const {Buffer} = require("buffer");

/** 
 * @typedef FullfillmentContribution
 * @param {string} txHash
 * @param {number} txIndex
 * @param {string} unlockingScript 
 * @param {boolean} fullfillment 
 * @param {string} scriptHash 
 * @param {string} address 
 * @param {string} lockingScript */

/** 
 * @typedef NonfullfillmentContribution
 * @param {string} txHash
 * @param {number} txIndex
 * @param {string} unlockingScript  
 * @param {string} seqNum 
 * @param {string} cid */

/**
 * 
 * @param {Transaction} transaction 
 * @param {Array<{address:string, satoshis:string}>} recipients recipients
 * @returns {FullfillmentContribution|NonfullfillmentContribution}
 */
function parseNotificationTransaction(transaction, recipients) {
  /** @type {Array<{value:number, script:Uint8Array}>} */
  const vouts = transaction.outs;

  /** @type {Array<{hash:Uint8Array,index:number,script:Uint8Array}>} */
  const vins = transaction.ins;

  if (isFullfillment(vouts, recipients)) {
    //Can't have an op return and be a fullfillment, unless op_return declared from get go and then, not necessary to fetch
    const commitments = vins.map((vin) => { 

      //Following isn't useful since missing satoshis anyways
      /** @type {import('bitbox-sdk').ECPair} */
      const ECPair = bitbox.ECPair;
      const publicKey = Buffer.from(vin.script).slice(-33);
      const ecPair = ECPair.fromPublicKey(publicKey);
      const address = ECPair.toCashAddress(ecPair);
      const outputScript = getP2PKHOutputScript(address);
      const scriptHash = getScriptHash(outputScript)

      return {
        txHash: Buffer.from(vin.hash).reverse().toString('hex'),
        txIndex: vin.index,
        unlockingScript: Buffer.from(vin.script).toString('hex'),
        fullfillment: true,
        scriptHash,
        address,
        lockingScript: Buffer.from(outputScript).toString('hex')
      }
    });

    //Also return fee (actual fee rate) or values
    return { isFullfillment: true, commitments }

  } else {

    /** @type {import('multiformats').CID} */
    const commitment = parseCommitmentOutput(vouts);

    if (commitment && commitment.txHash && typeof commitment.txIndex !== 'undefined' && commitment.unlockingScript) {
      return { isFullfillment: false, commitments: [commitment] }
    }
  }

  return {isFullfillment: false, commitments: [] }
}

/** 
 * @param {Array<{value:number, script:Uint8Array}>} vouts
 * @param {Array<{address:string, satoshis:number}>} recipients
 */
 function isFullfillment(vouts, recipients) {

  if (vouts.length !== recipients.length) {
    return false;
  }

  return vouts.every((vout, index) => {
    const recipient = recipients[index];
    
    if (recipient) {
      const actualValueInSatoshis = vout.value;
      const actualOutputScript = Buffer.from(vout.script).toString('hex');
      const expectedOutputScript = getP2PKHOutputScript(recipient.address).toString('hex');

      return actualValueInSatoshis === recipient.satoshis && actualOutputScript === expectedOutputScript;
    }

    return false;
  });
}

/** 
 * @param {Array<{value:number, script:Uint8Array}>} vouts
 */
 function parseCommitmentOutput(vouts) {
  try {
    const opReturnVout = vouts.find(vout => bitbox.Script.checkNullDataOutput(Buffer.from(vout.script)));
    if (opReturnVout) {
      return parseOpReturn(opReturnVout.script);
    }

    return null;
  } catch (err) {
    return null;
  }
}

function parseOpReturn(script) {
  const [opReturn, opReturnData] = bitbox.Script.decode(script);

  var offset = 0
  function readSlice (n) {
    offset += n
    return opReturnData.slice(offset - n, offset)
  }

  function readUInt32 () {
    var i = opReturnData.readUInt32LE(offset)
    offset += 4
    return i
  }

  //Ordering important here
  const txHash = readSlice(32).toString("hex");
  const txIndex = readUInt32();
  const seqNum = readUInt32();
  const cid = parseCid(readSlice(34));
  const unlockingScript = opReturnData.slice(offset).toString("hex");

  return { txHash, txIndex, seqNum, cid: cid && cid.toString(), unlockingScript };
}


function parseCid(data) {
  const cid = CID.asCID(data);
  if (cid) return cid;

  try { return CID.parse(data); } catch (err) { }
  try { return CID.decode(data).asCID } catch (err) {}
}

function getScriptHash(script) {
  const hash = bitbox.Crypto.sha256(Buffer.from(script));
  return hash.reverse().toString("hex")
}

function getP2PKHOutputScript(cashAddr) {
  const hash160 = bitbox.Address.cashToHash160(cashAddr);
  return bitbox.Script.fromASM("OP_DUP OP_HASH160 " + hash160 + " OP_EQUALVERIFY OP_CHECKSIG");
}


module.exports = { parseNotificationTransaction, getScriptHash }