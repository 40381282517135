import { useState, useEffect, useContext } from "preact/hooks"
import { createContext } from 'preact/compat';
import { useQueryClient } from 'react-query'

import { getWallet } from "@ipfs-flipstarter/utils/wallet/index.js";

function empty() { return null }
export const WalletContext = createContext({ seedPhrase: null, isLoading: false, utxos: [], fetchUtxos: empty, freezeUtxo: empty, broadcastTransaction: empty, toggleUtxoLock: empty });
export const useWallet = () => useContext(WalletContext);

export function WalletProvider({ children, seedPhrase, electrum }) {

  const [electrumReady, setElectrumReady] = useState(false);
  const [wallet, setWallet] = useState(null);
  const queryClient = useQueryClient();

  useEffect(async () => {
    const wallet = getWallet(seedPhrase);
    setWallet(wallet);
  }, [seedPhrase]);

  useEffect(async () => {

    if (!electrum) return;

    await electrum.ready();
    setElectrumReady(true);

  }, [electrum]);

  useEffect(async () => {
    if (electrumReady && !!wallet) {
      electrum.subscribe(() => {
        queryClient.invalidateQueries(['tx_history', wallet.scriptHash])
      }, "blockchain.scripthash.subscribe", wallet.scriptHash);
    }
  }, [electrumReady, wallet && wallet.scriptHash]);

  return <WalletContext.Provider value={{ ...wallet, seedPhrase, electrum }}>
    {children}
  </WalletContext.Provider>
}