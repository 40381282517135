import Router from "preact-router";
import { createHashHistory } from "history";

import Landing from "./pages/landing/index.js"
import DonationPage from "./pages/donation/index.js"
import Navigation from "./navigation.js";

export default function App({ campaign, initialValues, seedPhrase }) {
  return <div class="flex flex-col min-h-screen relative text-sm">
    <Router history={createHashHistory()}>
      <Landing default path="/" campaign={campaign} initialValues={initialValues}/>
      <DonationPage path="/donate/:defaultDonationAmount?:rest*" campaign={campaign} seedPhrase={seedPhrase}></DonationPage>
    </Router>
    <footer class="center grass mt-auto" style="min-height: 110px; display: flex; align-items:center;justify-content:center;">
      <p>Powered by <a class="text-blue-600" href="https://flipstarter.cash" target="_blank">Flipstarter</a></p>
    </footer>
  </div>
}