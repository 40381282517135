const { BITBOX } = require('bitbox-sdk');
const bitbox = new BITBOX();
const BIP39 = require("bip39");

const defaultHdPath = "m/44'/0'/0'/0/0"
const BITCOIN_NETWORK = "mainnet"
const WALLET_HD_PATH = defaultHdPath

module.exports = {
  createRecoveryPhrase,
  getWallet,
  createCommimentObject,
  getAddressScriptHash,
  createSimpleSendTransaction,
  feesFor,
  signMessage,
  getAddressFromLockscript,
  verifyMessage,
  keyPairToAddress,
  parseAddressFromOutputScript
}

function parseAddressFromOutputScript(script) {
  let address = ""

  try {
    
    const { pubKey } = bitbox.Script.decodeP2PKHInput(script) || [];
    return bitbox.Address.hash160ToCash(bitbox.Crypto.ripemd160(bitbox.Crypto.sha256(pubKey)));

  } catch(err) {

  }

  return address;
}

function createRecoveryPhrase() {
  return bitbox.Mnemonic.generate(128);
}

function getWallet(seedPhrase) {
  if (!BIP39.validateMnemonic(seedPhrase, BIP39.wordlists.EN)) throw new Error("invalid seed phrase");

  const seedBuffer = bitbox.Mnemonic.toSeed(seedPhrase);
  const rootHdNode = bitbox.HDNode.fromSeed(seedBuffer, BITCOIN_NETWORK);
  const hdNode = bitbox.HDNode.derivePath(rootHdNode, WALLET_HD_PATH);
  
  const legacyAddr = hdNode.keyPair.getAddress();
  const address = bitbox.Address.toCashAddress(legacyAddr);
  const scriptHash = getAddressScriptHash(address);
  const wif = hdNode.keyPair.toWIF();

  return {
    hdNode,
    address,
    scriptHash,
    wif
  }
}

function getAddressScriptHash(cashAddr) {
  const hash160 = bitbox.Address.cashToHash160(cashAddr);
  const outputScript = bitbox.Script.fromASM("OP_DUP OP_HASH160 " + hash160 + " OP_EQUALVERIFY OP_CHECKSIG");
  const scriptHash = bitbox.Crypto.sha256(outputScript).reverse().toString('hex');
  return scriptHash
}

/**
 * 
 * @param {import('bitbox-sdk').HDNode} hdNode 
 * @param {Array<{ amount: number, satoshis: number, txHash: string, txIndex: number }>} utxos 
 * @param {Array<{ amount: number, satoshis: number, address: string|Buffer }} recipients 
 * @param {Object} sendData 
 * @returns 
 */
function createSimpleSendTransaction(
  hdNode,
  utxos,
  recipients,
  locktime = 0,
  sendData = false
) {

  const keyPair = bitbox.HDNode.toKeyPair(hdNode);

  const tx = new bitbox.TransactionBuilder(BITCOIN_NETWORK);
  
  locktime = locktime || 0;

  let totalSatoshisIn = 0;
  utxos.forEach((utxo) => {
    tx.addInput(utxo.txHash, utxo.txIndex, locktime ? 0xFFFFFFFE : 0xFFFFFFFF);
    totalSatoshisIn += utxo.satoshis;
  });

  let totalSatoshisOut = 0;
  let dataOutputs = 0;
  recipients.forEach((recipient) => {
    if (recipient.data && sendData) {
      dataOutputs++
      const data = bitbox.Script.encodeNullDataOutput(recipient.data);
      tx.addOutput(data, 0);
    } else {
      tx.addOutput(recipient.address, recipient.satoshis);
    }
    totalSatoshisOut += recipient.satoshis;
  });

  //Last check if inputs don't make up for amount + fees
  if (totalSatoshisIn < totalSatoshisOut) {
    throw new Error("UTXO amount is less than requested amount")
  }

  tx.setLockTime(locktime);
  
  if (locktime) {
    tx
  }
  

  utxos.forEach((utxo, idx) => {
    tx.sign(
      idx,
      keyPair,
      undefined,
      tx.hashTypes.SIGHASH_ALL,
      utxo.satoshis,
      tx.signatureAlgorithms.SCHNORR
    );
  });

  return { tx: tx.build(), spent: totalSatoshisIn }
}

/**
 * 
 * @param {{txHash:string, txIndex:number, satoshis:number, data:any}} outpoint 
 * @param {Array<{address:string,satoshis:number}>} recipients 
 * @param {import('bitbox-sdk').ECPair} keyPair 
 * @returns 
 */
 function createCommimentObject(outpoint, recipients, keyPair) {
  //Create and sign a pledge tx moving coins from frozen addr to recipients of campaign.
  /** @type {import('bitbox-sdk').TransactionBuilder} */
  const commitTx = new bitbox.TransactionBuilder(BITCOIN_NETWORK);

  commitTx.addInput(outpoint.txHash, outpoint.txIndex);

  recipients.forEach(recipient => {
    commitTx.addOutput(recipient.address, parseInt(recipient.satoshis))
  });

  const vin = 0
  const redeemScript = undefined;
  const hashType = commitTx.hashTypes.SIGHASH_ALL | commitTx.hashTypes.SIGHASH_ANYONECANPAY
  const signatureAlgorithm = commitTx.signatureAlgorithms.ECDSA

  commitTx.sign(
    vin, 
    keyPair, 
    redeemScript, 
    hashType,
    outpoint.satoshis,
    signatureAlgorithm
  )

  const txin = commitTx.build().ins[0]
  const dataSignature = outpoint.dataMessage ? bitbox.BitcoinCash.signMessageWithPrivKey(keyPair.toWIF(), outpoint.dataMessage) : "";

  return {
    txHash: txin.hash.reverse().toString('hex'),
    txIndex: txin.index,
    unlockingScript: txin.script.toString('hex'),
    seqNum: txin.sequence,
    data: outpoint.data,
    dataSignature: dataSignature,
  };
}

function signMessage(keyPair, message) {
  return bitbox.BitcoinCash.signMessageWithPrivKey(keyPair.toWIF(), message);
}

function verifyMessage(address, signature, message) {
  return bitbox.BitcoinCash.verifyMessage(address, signature, message)
}

function feesFor(inputNum, outputNum) {
  return bitbox.BitcoinCash.getByteCount(
    { P2PKH: inputNum },
    { P2PKH: outputNum }
  );
}

function getAddressFromLockscript(lockScript) {
  return bitbox.Address.fromOutputScript(Buffer.from(lockScript, "hex"));
}

function keyPairToAddress(keyPair) {
  const legacyAddr = keyPair.getAddress();
  return bitbox.Address.toCashAddress(legacyAddr);
}