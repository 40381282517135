import {memo} from "preact/compat"
import Contribution from "../contributions/contribution.js"
import pluralize from "../../../../utils/pluralize.js"
import { useQueryClient } from "react-query";
import { useFundraiser } from "../../../withFundraiser.js";
import {contributionQueryKeys} from "@ipfs-flipstarter/utils/queries/contributions.js"
import { useContributionsQuery } from "../../../queries/useContributions.js";

function ContributionList({ contributions, goal }) {
  const contributionCount = contributions.length;
  const queryClient = useQueryClient();
  const { recipients } = useFundraiser();
  const { isFetching } = useContributionsQuery();

  function refreshContributions() {
    queryClient.invalidateQueries(contributionQueryKeys.list(recipients));
  }

  return <div class="text-lg">
    <div class="text-sm mb-8 flex justify-between">
      <div>
        <h1 class="text-2xl">Contributions</h1>
        <h2 class="whitespace-nowrap">{ contributionCount } {pluralize({ singular: "contribution", count: contributionCount })}</h2>
      </div>
      <button class="text-blue-500" onClick={refreshContributions}>Refresh</button>
    </div>
    <div className={`${!isFetching ? "hidden" : ""} text-gray-500 text-center text-lg mb-4`}>Loading...</div>
    {
      !contributionCount && <div class="text-center">
        <em class="text-grey-800">No one has made a pledge yet.</em>
        <br />
        <em class="text-grey-800">You could be the first.</em>
      </div>
    }
    <ul>
    {
      contributions.map(contribution => {
        return <Contribution key={contribution.txHash + ":" + contribution.txIndex} contribution={contribution} goal={goal}></Contribution>
      })
    }
    </ul>
  </div>
}

export default memo(ContributionList);