const { MIN_SATOSHIS } = require("../bitcoinCashUtilities.js");
const { keyPairToAddress, feesFor, createSimpleSendTransaction, createCommimentObject, signMessage } = require("../wallet/index.js");
const {CID} = require('ipfs');
const { encode:cborgencode } = require("cborg");

const maxOpReturnBytes = 220;
const notificationFee = feesFor(1, 1) + maxOpReturnBytes + MIN_SATOSHIS;

function chooseUtxosForDonation(totalAmount, utxos, outputNum) {
  const sortedUtxos = utxos.sort((a,b) => b.satoshis - a.satoshis);

  /**
   * Consolidation fee = fee to move utxos to 2 (setup + notification), 3 (setup, notification, tip/change), or 4 (setup, notification, tip, change) outputs
   *  If no utxos at the moment or not enough sats to cover costs, then consolidationFee is cost of moving 1 more utxo to some outputs
   */
  let consolidationFee = 0;
  let usingUtxos = [];
  let totalSatoshis = 0;

  for (const utxo of sortedUtxos) {
    usingUtxos.push(utxo);
    totalSatoshis += utxo.satoshis;
    consolidationFee = feesFor(usingUtxos.length, outputNum);

    if (totalSatoshis >= totalAmount + consolidationFee) break;
  }

  return usingUtxos;
}

async function createRefundTransaction(hdNode, address, utxo, refundTimestamp) {
  const fee = feesFor(1, 1);
  const { tx: refundTransaction } = createSimpleSendTransaction(hdNode, [utxo], [{ address, satoshis: utxo.satoshis - fee }], refundTimestamp);
  return refundTransaction;
}

//Default to refund of 0xFFFFFF locktime or always require one or don't create a refund transaction but only for download, not the type you can change.
async function createBlockchainFlipstarterTransaction(hdNode, outputs, utxos, donationAmount, recipients, refundAddress, refundTimestamp, applicationData, ipfs, defaultApiUrl) {

  const { tx: setupTransaction } = createSimpleSendTransaction(hdNode, utxos, outputs);
  const setupTransactionHash = setupTransaction.getId().toString("hex");

  
  const futureRefundTx = refundAddress && await createRefundTransaction(hdNode, refundAddress, { txHash: setupTransactionHash, txIndex: 0, satoshis: donationAmount }, refundTimestamp);
  const immediateRefundTx = refundAddress && await createRefundTransaction(hdNode, refundAddress, { txHash: setupTransactionHash, txIndex: 0, satoshis: donationAmount });
  
  //Application data would be comment, alias, etc.
  const hasApplicationData = Object.keys(applicationData || {}).length || futureRefundTx;
  const data = hasApplicationData && Object.assign(applicationData || {}, { 
    //Data depending on setup transaction would need to go here for now.
    refundTx: futureRefundTx.toHex() 
  });

  //Create data message to be signed by same keys making the commitment;
  const dataMessage = Buffer.from(cborgencode(JSON.stringify(data))).toString("base64");
  const commitmentOutpoint = { txHash: setupTransactionHash, txIndex: 0, satoshis: donationAmount, data, dataMessage };
  const commitmentData = createCommimentObject(commitmentOutpoint, recipients, hdNode.keyPair);

  //Hash the commitment information sent to IPFS.
  const commitmentCid = data ? await hashCommitmentData(ipfs, defaultApiUrl, commitmentData, recipients) : Buffer.alloc(34);

  //Create notification 
  const notificationData = await createNotificationData({ ...commitmentData, cid: commitmentCid });
  const notificationOutpoints = [{ txHash: setupTransactionHash, txIndex: 1, satoshis: notificationFee }];
  const notificationTransaction = createNotificationTransaction(hdNode, recipients[0].address, notificationOutpoints, notificationData);

  return [setupTransaction, notificationTransaction, futureRefundTx, immediateRefundTx, commitmentCid];
}

function createNotificationTransaction(hdNode, address, utxos, data) {
  
  const notificationOutputs = [
    { satoshis: MIN_SATOSHIS, address: address },
    { satoshis: 0, data: Buffer.from(data) }
  ]

  const { tx: notificationTransaction } = createSimpleSendTransaction(hdNode, utxos, notificationOutputs, null, true);
  return notificationTransaction;
}

async function hashCommitmentData(ipfs, defaultApiUrl, commitmentData, recipients) {

  const ipfsInfo = {
    //Information necessary to verify legit by flipstarter gateways
    recipients: recipients,
    txHash: commitmentData.txHash,
    txIndex: commitmentData.txIndex,
    unlockingScript: commitmentData.unlockingScript,
    seqNum: commitmentData.seqNum,

    applicationData: commitmentData.data,
    applicationDataSignature: commitmentData.dataSignature,
    version: "0.0.0"
  };

  //TODO God willing: do these in parallel or upload to ipfs in the background using state data.
  // Both might as well be in useQueries, God willing.
  let localIpfsResult, remoteIpfsApiResult;

  try {
    localIpfsResult = (await ipfs.add(JSON.stringify(ipfsInfo))).cid.toV0().bytes;
  } catch (err) {
    console.log("Failed to upload to IPFS:", err);
  }

  try {
    
    let formData = new FormData();
    formData.append('files', new Blob([JSON.stringify(ipfsInfo)]));
    const apiFetchResult = await fetch(defaultApiUrl + '/api/v0/add?cid-version=0', { method: 'POST', body: formData });
    const apiResult = await apiFetchResult.json();
    remoteIpfsApiResult = CID.parse(apiResult.Hash)?.toV0()?.bytes;

  } catch (err) {
    //TODO God willing
    console.log("Failed to uploada to default server");
  }

  if (remoteIpfsApiResult && localIpfsResult && remoteIpfsApiResult.toString('hex') !== localIpfsResult.toString('hex')) {
    console.log("Remote and local mismatch", "Remote: " + remoteIpfsApiResult.toString(), "Local: " + localIpfsResult.toString());
    return localIpfsResult;
  }

  return remoteIpfsApiResult || localIpfsResult;
}

async function createNotificationData(commitmentData) {
  //Serializes commitment and posts to blockchain using walletService.makeNotification (op_return)
  const txHash = Buffer.from(commitmentData.txHash, "hex");
  
  const outputIndex = Buffer.alloc(4);
  outputIndex.writeUInt32LE(commitmentData.txIndex);
  
  const sequenceNumber = Buffer.alloc(4);
  sequenceNumber.writeUInt32LE(commitmentData.seqNum);

  const unlockingScript = Buffer.from(commitmentData.unlockingScript, "hex");
  const cidBytes = commitmentData.cid.bytes || commitmentData.cid;
  
  //32 bytes, 32 bytes, 4 bytes, 4 bytes, 34 bytes, rest is unlocking.
  const outputData = Buffer.concat([txHash, outputIndex, sequenceNumber, cidBytes, unlockingScript]);

  if (outputData.byteLength > 220) {
    throw new Error("Unable to fit in one transaction");
  } 

  return outputData;
}

const FlipstarterAssuranceContract = require("../flipstarter/FlipstarterAssuranceContract.js");

const createFlipstarterFullfillmentTransaction = async (recipients, contributions) => {

  const contract = new FlipstarterAssuranceContract(recipients);
  
  // Add each recipient as outputs.
  recipients.forEach(recipient => {
    contract.addOutput(recipient)
  });

  //Order contributions from largest to smallest donations
  // Add relevant contributions to the contract..
  contributions.sort((a, b) => b.satoshis - a.satoshis).forEach(contribution => 
    contract.addCommitment(contribution)
  );

  // Fullfill contract if possible.
  if (contract.remainingCommitmentValue !== 0) throw new Error("Insufficient funds");

  // Assemble commitments into transaction
  const rawTransaction = contract.assembleTransaction();  
  return rawTransaction;
}

module.exports = { notificationFee, chooseUtxosForDonation, createBlockchainFlipstarterTransaction, createFlipstarterFullfillmentTransaction, hashCommitmentData, createRefundTransaction }
