const { getTransactionHistory, getTransaction, getUtxos } = require("../utils/electrum.js");

const blockchainQueryKeys = {
  all: () => ['blockchain'],
  transactionHistory: (scriptHash) => ['blockchain', 'tx_history', scriptHash],
  transaction: (txHash) => ['blockchain', 'transaction', txHash],
  utxos: (scriptHash) => ['blockchain', 'utxos', scriptHash],
}

function registerBlockchainQueries(queryClient, electrum) {
  queryClient.setQueryDefaults(blockchainQueryKeys.all(), {
    queryFn: ({ queryKey }) => {
      const [, scope, ...args] = queryKey;

      if (scope === 'tx_history') {
        const [recipientScripthash] = args;
        return getTransactionHistory(electrum, recipientScripthash);
      }

      if (scope === 'transaction') {
        const [txHash] = args;
        return getTransaction(electrum, txHash);
      }

      if (scope === 'utxos') {
        const [scriptHash] = args;
        return getUtxos(electrum, scriptHash);
      }
    }
  })
}

module.exports = { blockchainQueryKeys, registerBlockchainQueries }