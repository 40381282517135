import { useState } from "preact/hooks";
import { useWallet } from "./withWallet.js";

import { Link, useRouter } from "preact-router";

import logo from "../../public/img/ipfs-flipstarter-logo.svg"
import leftArrowSvg from "../../public/img/chevron-left-solid.svg"

import BackupModal from "./pages/donation/modals/backup-modal.js";

async function loadSideshiftScript() {
  const id = "sideshiftScript"
  if (!document.getElementById(id)) {
    const script = document.createElement('script');
    
    script.id = id;
    script.src = 'https://sideshift.ai/static/js/main.js';
    
    document.body.appendChild(script);
    
    return new Promise((res, rej) => {
      script.onload = () => { 
        res();
      };
    });
  }
}

export default function Navigation() {
  const [{ path }] = useRouter();
  const { address, seedPhrase } = useWallet();

  const isDonate = path === "/donate";
  const [showBackupModal, setShowBackupModal] = useState(false);

  function openBackupModal() {
    setShowBackupModal(true);
    loadSideshiftScript().then(() => {
      
      window.__SIDESHIFT__ = {
        parentAffiliateId: "Q5Rcvkcp2",
        defaultDepositMethodId: "btc",
        defaultSettleMethodId: "bch",
        settleAddress: address,
      }
    });
  }

  async function closeBackupModal(e) {
    e.preventDefault();
    setShowBackupModal(false);

    await loadSideshiftScript()
    window?.sideshift?.show?.();
  }

  return <>
    <header className={`p-2 ${ isDonate ? "sticky top-0 bg-white border-b shadow-sm z-10": ""}`}>
      <nav class={`bg-transparent grid grid-cols-3 items-center`}>
        <div class="lg:invisible">
          <div class="text-lg w-6 p-1 mr-2">
            { isDonate && <Link href="/"><img class="h-full" src={leftArrowSvg}></img></Link> }
          </div>
        </div>
        <a class="h-full flex justify-center" href={ !isDonate && "https://flipstarter.me"} target="_blank"><img src={logo} style="width:120px;height: 100%;"></img></a>
        <div class="flex justify-center">
          <button class="p-1" onClick={openBackupModal} id="sideshift-modal-button">Need BCH?</button>
        </div>
      </nav>
    </header>

    { showBackupModal && <BackupModal seedPhrase={seedPhrase} showKeepOption={false} onHide={closeBackupModal}>
      <div class="font-bold text-center underline">Sideshift.ai is not available in certain countries.</div>
    </BackupModal> }
  </>
}