import Modal from "../../../modal.js";
import PrimaryButton from "../../../inputs/primary-button.js";
import SecondaryButton from "../../../inputs/secondary-button.js";
import FloatingLabel from "../../../inputs/floating-label.js";

import {QRCodeCanvas} from "qrcode.react";
import copyToClipboard from "copy-to-clipboard";
import { useMutation } from "react-query";

import { useState, useContext, useEffect } from "preact/hooks";
import useCopy from "../../../hooks/useCopy.js";

import { useWallet } from "../../../withWallet.js";
import prettyPrintSats from "../../../../utils/prettyPrintSats.js";
import { SATS_PER_BCH, MIN_SATOSHIS } from "../../../../utils/bchConstants.js";

import { feesFor } from "@ipfs-flipstarter/utils/wallet/index.js";

import useWalletUtxos from "../../../queries/useWalletBalance.js";
import useBroadcastTransaction from "../../../queries/useBroadcastTransaction.js";
import useToggleUtxoLock from "../../../queries/useToggleUtxoLock.js";
import { createRefundTransaction, createBlockchainFlipstarterTransaction, chooseUtxosForDonation, notificationFee } from "@ipfs-flipstarter/utils/utils/flipstarterTransactions.js";
import { useIpfs } from "../../../withIpfs.js";

import {CID} from "ipfs";
import CompleteCheckmarkLoader from "../../../inputs/checkmark-completed.js";
import { route } from "preact-router";
import { useQueryClient } from "react-query";
import { contributionQueryKeys } from "@ipfs-flipstarter/utils/queries/contributions.js";
import styled from "styled-components"

import moment from "moment"

const CheckoutGrid = styled.div`
  display: grid;
  grid-template-areas: 
    "qr"
    "copy"
    "open"
    "amount"
    "check";
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: auto;


  @media screen and (min-width: 640px) {
    grid-template-areas:
      "qr qr amount amount"
      "copy open check check";
    grid-template-columns: 25% 25% 25% 25%;
    grid-column-gap: 0rem;
    grid-row-gap: 0rem;
  }

`;

export default function CheckoutModal({ donationAmount, onHide, recipients, alias, comment, refundTimestamp }) {
  donationAmount = Number(donationAmount);

  const { ipfs, error:ipfsError } = useIpfs();
  const { address, hdNode, wif } = useWallet();

  const { isFetching:isFetchingUtxos, data:walletInfo, refetch:fetchUtxos } = useWalletUtxos();
  const { utxos, previousAddress } = Object.assign({ utxos: [], previousAddress: "" }, walletInfo || {});
  
  const toggleUtxoLock = useToggleUtxoLock();
  const broadcastTransaction = useBroadcastTransaction();
  
  const [requestingAmount, setRequestingAmount] = useState(null);
  const [availableAmount, setAvailableAmount] = useState(0);
  const [feesAmount, setFeesAmount] = useState(0);
  const [showManageUtxos, setShowManageUtxos] = useState(false);
  const [userRefundAddress, setUserRefundAddress] = useState();
  const [userRefundAddressDirty, setUserRefundAddressDirty] = useState(false);

  //Use previous address unless user changed the refundAddress
  const refundAddress = userRefundAddressDirty ? userRefundAddress : previousAddress;

  const [finalTransactions, setFinalTransactions] = useState(null);
  const [showError, setShowError] = useState(false);
  
  const bip21Request = `${address}?amount=${(requestingAmount) / SATS_PER_BCH}`;
  const [copyAddressToClipboard, showSuccessfulAddressCopy] = useCopy(bip21Request);
  
  const [donationAmountText, donationAmountDenomination] = prettyPrintSats(donationAmount);
  const [availableAmountText, availableAmountDenomination] = prettyPrintSats(availableAmount);
  const [requestingAmountText, requestingAmountDenomination] = prettyPrintSats(requestingAmount);
  const [feesAmountText, feesAmountDenomination] = prettyPrintSats(feesAmount);

  const [makingPledge, setMakingPledge] = useState(false);
  const [madePledge, setMadePledge] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    const availableUtxos = utxos.filter(utxo => !utxo.isLocked);
    const availableAmount = availableUtxos.reduce((sum, utxo) => {
      return sum + utxo.satoshis;
    }, 0);

    setAvailableAmount(availableAmount);
  }, [utxos]);

  useEffect(async () => {
    if (madePledge) {
      await queryClient.cancelQueries(contributionQueryKeys.list());
      await queryClient.invalidateQueries(contributionQueryKeys.list());
      setTimeout(() => route('/'), 2000);
    }
  }, [madePledge]);

  useEffect(() => {
    if (makingPledge || madePledge) {
      setFinalTransactions(null);
      setFeesAmount(0);
      setRequestingAmount(0);
      return;
    };

    const outputNum = 2;
    const availableUtxos = utxos.filter(utxo => !utxo.isLocked);
    const spendUtxos = chooseUtxosForDonation(donationAmount + notificationFee, availableUtxos, outputNum);

    const totalSatoshis = spendUtxos.reduce((sum, utxo) => sum + utxo.satoshis, 0);
    const currentConsolidationFee = feesFor(spendUtxos.length, outputNum);

    const consolidationFee = totalSatoshis >= donationAmount + notificationFee + currentConsolidationFee ?
      //Enough to cover donation + fees
      currentConsolidationFee : 
      //Not enough satoshis to cover the donation + notification + consolidation fee.
      feesFor(spendUtxos.length + 1, outputNum);
      
    const donationWithFees = donationAmount + notificationFee + consolidationFee;
    const requestingAmount = donationWithFees > totalSatoshis ? donationWithFees - totalSatoshis : 0;
    
    if (requestingAmount && requestingAmount < MIN_SATOSHIS) {
      const extraFee = MIN_SATOSHIS - requestingAmount;
      setFeesAmount(consolidationFee + notificationFee + extraFee);
      setRequestingAmount(requestingAmount + extraFee);
    } else {
      setFeesAmount(consolidationFee + notificationFee);
      setRequestingAmount(requestingAmount);
    }

    
  }, [utxos, donationAmount, makingPledge, madePledge]);

  useEffect(async () => {
    //Prevent creating transactions when donationAmount itself is less than MIN_SATOSHIS
    if (donationAmount < MIN_SATOSHIS || !hdNode || !address) {
      return setFinalTransactions(null);
    }

    //Prevent creating transactions when donationAmount itself is less than MIN_SATOSHIS
    if (donationAmount < MIN_SATOSHIS || !hdNode || !address) {
      return setFinalTransactions(null);
    }

    //Prevent creating transaction before we have a previous address for refunds and user didn't remove it.
    // Should exist soon after recieving any funds. 
    if (!refundAddress && !userRefundAddressDirty) {
      return setFinalTransactions(null);
    }

    //Allow creating transactions when not requesting anything more.
    if(requestingAmount === 0) {

      try {
        
        const availableUtxos = utxos.filter(utxo => !utxo.isLocked);
                
        //Consolidate coins to one utxo that will be committed
        // always creating a notification utxo to use as a notification tx
        const setupOutputs = [

          { satoshis: donationAmount, address },
          { satoshis: notificationFee, address }
        ];

        const totalAmountDue = setupOutputs.reduce((sum, utxo) => sum + utxo.satoshis, 0);
        const spendUtxos = chooseUtxosForDonation(totalAmountDue, availableUtxos, setupOutputs.length);

        const data = comment || alias ? { comment: comment || "", alias: alias || "" } : null;

        const [setupTransaction, notificationTransaction, futureRefundTransaction, immediateRefundTransaction, commitmentCid] = await createBlockchainFlipstarterTransaction(hdNode, setupOutputs, spendUtxos, donationAmount, recipients, refundAddress, refundTimestamp, data, ipfs, process.env.DEFAULT_API_URL);
      
        setFinalTransactions([setupTransaction, notificationTransaction, futureRefundTransaction, immediateRefundTransaction, commitmentCid]);

      } catch (err) {
        //Set transactions to null in case creation fails for some reason.
        setFinalTransactions(null);  

        console.log(err);
        setShowError(err);
      }
    }
  }, [hdNode, address, requestingAmount, donationAmount, comment, alias, recipients, refundAddress]);

  async function makePledge() {
    
    setMakingPledge(true);

    const [setupTransaction, notificationTransaction, futureRefundTransaction, immediateRefundTransaction, commitmentCid] = finalTransactions;
    
    let setupTransactionHash, notificationTransactionHash;

    try {
      try {

        if (commitmentCid) {
          const cid = CID.decode(commitmentCid);
          await ipfs.connectToPreloadNodes(process.env.PRELOAD_NODES);
          await ipfs.requestPreloading(cid.toString(), process.env.PRELOAD_NODES, 5000);
        }

      } catch (err) {
        console.log("Error preloading data", err);
      }

      try {
        
        const setupTransactionHex = setupTransaction.toHex();
        setupTransactionHash = await broadcastTransaction(setupTransactionHex);
        if (!setupTransactionHash) throw new Error("Invalid transaction hash returned: " + setupTransactionHash);
        
        console.log(`Setup tx hash: (${setupTransactionHash})`);

      } catch (err) {
        console.log("Error broadcasting setup transaction", err);
        return setShowError(true) //setSetupError(err);
      }
      
      try {
        
        const notificationTransactionHex = notificationTransaction.toHex();
        notificationTransactionHash = await broadcastTransaction(notificationTransactionHex);
        if (!notificationTransactionHash) throw new Error("Invalid transaction hash returned: " + notificationTransactionHash);

        console.log(`Notification tx hash: (${notificationTransactionHash})`);

      } catch (err) {

        console.log("Error broadcasting notification transaction", err);
        return setShowError(true) //setNotificationError(err);
      }

      try {
        
        await toggleUtxoLock({ txHash: setupTransactionHash, txIndex: 0, isLocked: false });

      } catch (err) {

        console.log("Error freezing coins", err);
      }

      console.log("Immediate:", immediateRefundTransaction && immediateRefundTransaction.toHex());
      console.log("Future refund:", futureRefundTransaction && futureRefundTransaction.toHex());

      setMadePledge(true);

    } finally {
      setMakingPledge(false);
    }
  }

  function onCopyAddress(e) {
    copyAddressToClipboard();
  }

  function onCheckBalance(e) {
    fetchUtxos();
  }
  
  function onToggleLock(utxo) {
    toggleUtxoLock(utxo);
  }

  function onShowKey(e) {
    setShowKey(true);
  }

  function onHideKey(e) {
    e.stopPropagation();
    setShowKey(false);
  }

  const [showKey, setShowKey] = useState(false);
  const [showRefund, setShowRefund] = useState(null);

  const subheading = <>
    This is a <em>non-custodial crowdfunding</em> web application. Funds will not leave your wallet unless the campaign reaches it's goal. {/* <a class="text-blue-400 cursor-pointer" href="/">Learn more.</a> */}
    { !showRefund ? <div 
      class="bg-green-200 mt-6 p-4 text-center text-xs text-gray-900 cursor-pointer relative" 
      onClick={onShowKey}>
        <div class="flex flex-row gap-4 items-center justify-around">
          { showKey ? 
          <>
            <div className="cursor-text break-words overflow-hidden">{wif}</div>
            <div className="h-full bg-gray-400 px-4 py-2 text-center inline-flex justify-center items-center rounded text-white shadow-xl cursor-pointer hover:bg-gray-500" onClick={onHideKey}>Hide</div>
          </> : "Click here to show your web wallet's private key. This can be used to revoke your pledge." }
        </div>
    </div> : <div className="mt-8 mb-4 text-sm font-bold">{
      showRefund.immediateRefundTransaction || !showRefund.futureRefundTransaction || showRefund.futureRefundTimestamp > moment.unix() ? 
        "Where would you like to send this UTXO?" :
        "This cannot be refunded for " + moment.unix().to(moment.unix(showRefund.futureRefundTimestamp), true) + " on " + moment.unix(showRefund.futureRefundTimestamp).format("dddd, MMMM Do YYYY, h:mm:ss a")
    }</div>
  }</>

  const footer = <div class="flex flex-col sm:flex-row sm:items-center justify-end gap-2">
    <SecondaryButton onClick={(e) => onHide(true)}>
      <span>Cancel</span>
    </SecondaryButton>
    { !showManageUtxos ? <PrimaryButton data-testid="pledgeButton" disabled={!finalTransactions} onClick={(e) => makePledge()}>
      <span>{ madePledge ? "Done!" : makingPledge ? "Loading..." : "Pledge" }</span>
    </PrimaryButton> : showRefund ? <PrimaryButton data-testid="refundButton" onClick={(e) => refundUtxo(showRefund)}>
      <span>Refund</span>
    </PrimaryButton> : <></> }
    <a class="mr-auto text-blue-500 underline pl-1 pb-1 sm:pl-0 sm:pr-0 sm:order-first" href="" onClick={(e) => { e.preventDefault(); setShowManageUtxos(!showManageUtxos); setShowRefund(null);}}>{showManageUtxos ? "Back to checkout" : "Manage wallet"}</a>
  </div>

  async function refundUtxo(utxo) {
    try {
      
      const refundTx = utxo.immediateRefundTransaction || await createRefundTransaction(hdNode, refundAddress, utxo);
      const refundTxHex = refundTx.toHex();

      const refundTxHash = await broadcastTransaction(refundTxHex);
      if (!refundTxHash) throw new Error("Invalid transaction hash returned: " + refundTxHash);

      console.log(`Notification tx hash: (${refundTxHash})`);
      
      setShowRefund(null);

    } catch (err) {
      console.log("Error broadcasting refund transaction", err);
      throw err;
    }
  }

  function onRefundAddressChanged(e) {
    setUserRefundAddressDirty(true);
    setUserRefundAddress(e.target.value);
  }

  function onRefundUtxo(utxo, e) {
    e.preventDefault();
    console.log(utxo);
    setShowRefund(utxo);
  }

  return <Modal 
    heading="Ready for checkout?" 
    subheading={subheading} 
    footer={footer}>
      <div>
        { !showManageUtxos && <>
          <CheckoutGrid>
              <div style="grid-area: qr" class="flex flex-col gap-4 items-center justify-center">
                <div className="mt-8 mb-4 mx-auto relative">
                  <QRCodeCanvas value={bip21Request} className={!!finalTransactions || makingPledge || madePledge ? 'invisible' : ''}></QRCodeCanvas> 
                  <div className={`absolute inset-0 z-10 flex justify-center items-center ${!finalTransactions && !makingPledge && !madePledge ? 'hidden' : ''}`}>
                    <CompleteCheckmarkLoader loadComplete={!!finalTransactions || madePledge} ></CompleteCheckmarkLoader>
                  </div>
                </div>
              </div>
              { !finalTransactions && !makingPledge && !madePledge && <>
                <div style="grid-area: copy;" className="flex items-center justify-center">
                  <button class="w-2/3 sm:w-auto justify-center inline-flex items-center rounded-2xl text-center text-white py-2 px-4 rounded-full bg-gradient-to-r from-green-500 to-green-700" onClick={onCopyAddress}>{ showSuccessfulAddressCopy ? "Copied" : "Copy Address"}</button>
                </div>
                <div style="grid-area: open;" className="flex items-center justify-center">
                  <a href={bip21Request} class="w-2/3 sm:w-auto justify-center inline-flex items-center rounded-2xl text-center text-white py-2 px-4 rounded-full bg-gradient-to-r from-yellow-500 to-yellow-700">Open in wallet</a>
                </div>
                <div style="grid-area: check;" className="flex items-center justify-center">
                  <button class="xs:w-2/3 justify-center inline-flex items-center rounded-2xl text-center py-2 px-4 rounded-full border border-green-600 text-green-600" onClick={onCheckBalance}>{ isFetchingUtxos ? "Loading..." : "Check balance" }</button>
                </div>
              </> }
              <div style="grid-area: amount;" class="xs:p-8">
                <div class="mb-4 text-center xs:text-left"><b>Your donation</b></div>
                <dl class="grid grid-cols-2 text-gray-500">
                  <dt class="">Total</dt>
                  <dd data-testid="checkoutTotalAmount" class="text-right mb-2"><span class="whitespace-nowrap">{donationAmountText} {donationAmountDenomination}</span></dd>
                  
                  <dt class="pr-4">Avail. Bal.</dt>
                  <dd data-testid="checkoutAvailableAmount" class="text-right mb-2"><span class="whitespace-nowrap">{availableAmountText} {availableAmountDenomination}</span></dd>
                  
                  <dt class="pr-4">Network Fees</dt>
                  <dd data-testid="checkoutNetworkFeesAmount" class="text-right mb-2"><span class="whitespace-nowrap">{feesAmountText} SATS</span></dd>

                  <dt class="pr-4 pt-4 text-gray-700 font-semibold">Requesting</dt>
                  <dd data-testid="checkoutRequestingAmount" class="text-right pt-4 text-gray-700 font-semibold"><span class="whitespace-nowrap">{requestingAmountText} {requestingAmountDenomination}</span></dd>
                </dl>
                { showError && <div class="flex gap-2 items-center my-2 text-red-500"><span class="icon-info"></span><span class="">Something went wrong. Please try again.</span></div> }
              </div> 
          </CheckoutGrid>
          { (!!finalTransactions || makingPledge || madePledge) && <div class="pb-4">
              <div className="my-4 text-sm font-bold">Send refund to what address if the campaign expires? <small>(leave blank if none)</small></div>
              <div class="flex relative border rounded m-1 text-gray-500 pt-6 px-4 pb-2 ">
                <input defaultValue={previousAddress} class="w-full peer text-black outline-0" id="alias" type="text" name="alias" placeholder="&nbsp;" onChange={onRefundAddressChanged}></input>
                <FloatingLabel for="alias" className="absolute top-1 left-4 translate-y-3 bg-transparent">Address</FloatingLabel>
              </div>
          </div>}
        </>}

        {
          showManageUtxos && !showRefund ? <div class="overflow-x-auto w-full">
            { 
              !utxos.length ? 
                <div class="text-center text-gray-600 text-lg py-6 font-thin">No utxos found in this wallet.</div> :
                <table class="table w-full">
                  {/* <!-- head --> */}
                  <thead>
                    <tr>
                      <th class="pr-2">Locked</th>
                      <th>Height</th>
                      <th>Transaction</th>
                      <th>Satoshis</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    { utxos.map((utxo) => {
                      return <tr key={utxo.txHash + ":" + utxo.txIndex}>
                        <th class="pr-2">
                          <label>
                            <input type="checkbox" class="checkbox" checked={!!utxo.isLocked} onChange={() => onToggleLock(utxo)} />
                          </label>
                        </th>
                        <td class="pr-2">{ utxo.height }</td>
                        <td class="pr-2">
                          <div class="text-sm"><a class="text-blue-500" target="_blank" href={`https://blockchair.com/bitcoin-cash/transaction/${utxo.txHash}`} alt={utxo.txHash}>{ utxo.txHash.slice(0, 12) }:{ utxo.txIndex }</a></div>
                          { utxo.isCommitted && <><br/><span class="badge badge-ghost badge-sm">Committed</span></> }
                        </td>
                        <td class="pr-2">{ utxo.satoshis }</td>
                        <th>
                          <button class="btn btn-ghost btn-xs" onClick={onRefundUtxo.bind(null, utxo)}>refund</button>
                        </th>
                      </tr>}) 
                    }
                  </tbody>    
                </table>
            }
          </div> : showRefund ? <div>
            <div class="flex relative border rounded m-1 text-gray-500 pt-6 px-4 pb-2 ">
              <input defaultValue={previousAddress} class="w-full peer text-black outline-0" id="alias" type="text" name="alias" placeholder="&nbsp;" onChange={onRefundAddressChanged}></input>
              <FloatingLabel for="alias" className="absolute top-1 left-4 translate-y-3 bg-transparent">Address</FloatingLabel>
            </div>
          </div> : <></>
        }
      </div>
  </Modal>
}