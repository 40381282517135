import Modal from "../../../modal.js";
import SecondaryButton from "../../../inputs/secondary-button.js";

import Checkbox from "../../../inputs/checkbox.js";
import { useState } from "preact/hooks";
import { useWallet } from "../../../withWallet.js";

export default function BackupModal({ showKeepOption = true, keepWallet, onKeepWalletChanged, onHide, children }) {
  const [seedRevealed, setSeedRevealed] = useState(false);
  const [hideSeed, setHideSeed] = useState(true);
  const { seedPhrase, wif } = useWallet();

  function onSeedRevealed() {
    setSeedRevealed(true);
    setHideSeed(!hideSeed);
  }

  function _onKeepWalletChanged() {
    onKeepWalletChanged && onKeepWalletChanged(e.target.value)
  }

  const subheading = <>
    This is a <em>non-custodial web wallet</em> so keep funds low unless you're comfortable. {/* <a class="text-blue-400 cursor-pointer" href="/">Learn more.</a> */}
  </>

  const footer = <div class="mt-6 flex gap-4 xs:flex-wrap">
    <div class="mt-3 rounded-lg sm:mt-0 sm:ml-3">
      <button class="disabled:bg-gray-600 items-center block px-10 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-green-600 rounded-xl hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500" onClick={onSeedRevealed}><span>{ !hideSeed ? "Hide seed" : "Reveal seed" }</span></button>
    </div>
    { seedRevealed && <div class="mt-3 rounded-lg sm:mt-0">
      <button class="items-center block px-10 py-3.5 text-base font-medium text-center text-green-600 transition duration-500 ease-in-out transform border-2 border-white shadow-md rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" onClick={onHide}><span>Done</span></button>
    </div> }
  </div>

  return <Modal 
    heading="Let's make sure you backup your wallet seed!" 
    subheading={subheading} 
    footer={footer}>
      <div class="mt-3 text-left sm:mt-5">

        <p class="mx-auto mt-2 text-base leading-relazed text-gray-500">Spending from this wallet or sweeping the private key will revoke your contributions.</p>
        
        { children ? <p class="mt-3 mx-auto text-base leading-relazed text-gray-500">
          { children }
        </p> : <></> }

        <p class="mx-auto my-8 text-base leading-relaxed text-2xl text-green-600">
          { !hideSeed && <ol class="appearance-none flex flex-wrap gap-4 justify-center items-center">
            {
              seedPhrase.split(" ").map((word) => {
                return <li>{ word }</li>
              })
            }
          </ol> }
          
          { !hideSeed && <div class="flex flex-col justify-center pt-8 pb-6 py-4 rounded text-gray-800 text-sm">
            <span class="text-center font-bold">Private key</span>
            <span class="break-words text-center w-full">{ wif }</span>
          </div>}
          { showKeepOption && seedRevealed && <div class="flex items-center mt-8 text-lg">
            <Checkbox className="mr-4" id="keepWallet" type="checkbox" checked={!!keepWallet} onChange={_onKeepWalletChanged}></Checkbox>
            <label for="keepWallet" class="text-gray-800">Keep your web wallet in memory?</label>
          </div> }
        </p>
      </div>
  </Modal>
}