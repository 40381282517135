import { useQueryClient, useMutation } from 'react-query'

import { freezeUtxo, unfreezeUtxo } from "../../utils/frozen-coins.js"
import { useWallet } from '../withWallet.js';

import { walletQueryKeys } from './useWalletBalance.js';

export default function useToggleUtxoLock() {
  const { scriptHash } = useWallet();
  const queryClient = useQueryClient();
  const queryId = walletQueryKeys.unspent(scriptHash);

  const toggleUtxoLock = useMutation(async (utxo) => {
    if (!utxo.isLocked) {
      return freezeUtxo(utxo.txHash, utxo.txIndex);
    } else {
      return unfreezeUtxo(utxo.txHash, utxo.txIndex);
    }
  }, {
    onMutate: async (utxo) => {

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryId);
      
      // Snapshot the previous value
      const previousUtxos = queryClient.getQueryData(queryId);

      queryClient.setQueryData(queryId, ({ utxos, previousAddress }) => {
        const otherUtxos = utxos.filter(u => u.txHash !== utxo.txHash || u.txIndex !== utxo.txIndex);
        return { utxos: [...otherUtxos, { ...utxo, isLocked: !utxo.isLocked }], previousAddress }
      });
      
      // Return a context object with the snapshotted value
      return { previousUtxos }
    },
    onError: (err, utxo, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      return queryClient.setQueryData(queryId, context.previousUtxos)
    },
    onSettled: () => {
      // Always refetch after error or success:
      return queryClient.invalidateQueries(queryId)
    },
  });

  return toggleUtxoLock.mutateAsync
}