import CtaButtons from "./cta-buttons.js"
import DonationWidget from "./donations/donation-widget.js"
import Summary from "./summary.js"
import RecipientList from "./recipients/recipient-list.js"
import ContributionList from "./contributions/contribution-list.js"
import Navigation from "../../navigation.js";
import Modal from "../../modal.js"
import Share from "./share.js"
import PrimaryButton from "../../inputs/primary-button.js"
import FloatingLabel from "../../inputs/floating-label.js"

import parseMarkdown from "../../../utils/markdown.js"
import { useEffect, useState, useMemo, useContext, useRef, useCallback } from "preact/hooks"
import { useInView } from "react-intersection-observer"
import useCopy from "../../hooks/useCopy.js"
import { route } from "preact-router"

import { useContributionsQuery } from "../../queries/useContributions.js"
import moment from "moment"

import createDOMPurify from 'dompurify';
const DOMPurify = createDOMPurify(window);

export default function App({ campaign = {} }) {

  if (!campaign) campaign = { recipients: [] }

  const title = campaign.title;
  const requestedAmount = useMemo(() => {
    return campaign.recipients.reduce((sum, recipient) => {
      return sum + recipient.satoshis;
    }, 0);
  }, [campaign.recipients])
  
  const contributionsQuery = useContributionsQuery(campaign?.recipients);
  const { totalRaised:amountRaised = 0, contributions = [] } = contributionsQuery.data || {};

  const [description, setDescription] = useState("");
  const [showShare, setShowShare] = useState(false);
  const [copyLink, shareCopySuccess] = useCopy(window.location.href);

  useEffect(async () => {
    const description = DOMPurify.sanitize(await parseMarkdown(campaign.description));
    setDescription(description);
  }, []);
  
  const recipients = campaign.recipients;
  const contributionCount = contributions.length;  

  const [hideModal, setHideModal] = useState(true);
  
  // Use object destructing, so you don't need to remember the exact order
  const donateWidgetRef = useRef();
  const [donateWidgetInViewRef, donateWidgetIsInView ] = useInView({ initialInView: true });

  // Use `useCallback` so we don't recreate the function on each render - Could result in infinite loop
  const donateWidgetInViewRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      donateWidgetRef.current = node;
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      donateWidgetInViewRef(node);
    },
    [donateWidgetInViewRef],
  );

  const [scrollHeight, setScrollHeight] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollHeight(window.scrollY);
    })
  }, [])

  const isAboveButtons = (donateWidgetRef.current && donateWidgetRef.current.scrollHeight > scrollHeight)
  const showStickyCta = !donateWidgetIsInView && !isAboveButtons;

  function onPledge() {
    route("/donate");
  }

  function onShare() {
    setShowShare(true);
  }
  
  // const { url:userUrl = "", name:userName = "" } = campaign?.recipients?.[0] || {}
  // const userLink = useMemo(() => {
    
  //   return DOMPurify.sanitize(`
  //     <div><span>This fundraiser is organized by&nbsp;<a href="${recipients?.[0]?.url}"><span><b>${recipients[0].name}</b></span></a></span></div>
  //   `)
  // }, [userUrl, userName]);

  const heroImage = campaign.image || (campaign.recipients && campaign.recipients.length && campaign.recipients[0].image)
  const notExpired = campaign.expires && campaign.expires > moment.unix();

  return <>
    <Navigation></Navigation>
    <div>
      {/* <!-- Site content --> */}
      <main class="px-3 sm:px-4 lg:py-8 lg:px-16 m-auto rounded-2xl">
        {/* <!-- Campaign --> */}
        <article id="campaign" class="">
          <div class="main-content bg-transparent sm:py-8" style="grid-area:hero;">
            <div class="bg-gray-200 relative rounded-xl" style="padding-top:56%;">
              <div class="absolute w-full h-full rounded-xl inset-0 bg-cover bg-center bg-no-repeat" style={heroImage ? `background-image:url(${heroImage})` : ''}></div>
            </div>
          </div>
          <div class="main-content title mt-5" style="grid-area:title;">
            <h5 class="text-3xl font-bold">{ title }</h5>  
          </div>

          {/* <!-- Campaign overview --> */}
          <section class="sidebar pl-6 my-4" style="grid-area:sidebar;">
            <DonationWidget
              ref={donateWidgetInViewRefs}
              requestedAmount={requestedAmount}
              amountRaised={amountRaised}
              contributionCount={contributionCount}
              onPledge={onPledge}
              onShare={onShare}
              expires={campaign.expires}
            ></DonationWidget>
          </section>
          
          <div class="sm:px-4 min-w-full" style="grid-area: content;">
            {
              recipients.length ?
                <div class="hidden lg:flex mb-6 flex-nowrap gap-3 justify-between border border-x-0 p-2 sm:p-4 border-gray-300" style="" /*dangerouslySetInnerHTML={{__html: userLink}}*/>
                  <div><span>This fundraiser is organized by&nbsp;<a href={recipients?.[0]?.url} target="_blank"><span><b>{recipients?.[0]?.name}</b></span></a></span></div>
                </div> :
                <></>
            }

            {/* <!-- Campaign heading --> */}
            <div>
              <Summary description={description}></Summary>
            </div>

            {/* <!-- CTA Links --> */}
            <div class="my-8">
            { !!notExpired && <CtaButtons forceRow={true} onPledge={onPledge} onShare={onShare}></CtaButtons> }
            </div>

            <div class="border border-x-0 p-4 py-8 border-gray-300">
              <RecipientList recipients={recipients}></RecipientList>
            </div>

            <div class="p-4 py-8">
              <ContributionList contributions={contributions} goal={requestedAmount} ></ContributionList>
            </div>
          </div>
        </article>
      </main>
    </div>
    
    {
      showShare && <Modal
        heading="Help by raising awareness."
        subheading="Fundraisers shared on social networks raise up to 5x more"
        footer={
          <div className="absolute right-4 top-3 cursor-pointer select-none" onClick={() => setShowShare(false)}>✕</div>
        }
      >
        <div>
          <Share data={{
            text: campaign.description,
            url: window.location.href,
            title: campaign.title
          }}>
            <button>Share</button>
          </Share>
        </div>
        <div class="flex justify-betwwen gap-4 mt-4">
          <div class="w-full flex relative border rounded m-1 text-gray-500 px-4" onClick={() => copyLink()}>
            <input readonly class="w-full text-black outline-0" id="alias" type="text" name="alias" placeholder="&nbsp;" value={window.location.href} onClick={() => copyLink()}></input>
            <FloatingLabel className="absolute top-1 left-4 -translate-y-3.5 bg-white text-black text-sm" onClick={() => copyLink()}>Copy link</FloatingLabel>
          </div>
          <PrimaryButton onClick={() => copyLink()}>Copy</PrimaryButton>
        </div>
        <div className={`${!shareCopySuccess ? 'invisible' : ''} ml-6 text-green-400`}>✓ Copied!</div>
      </Modal>
    }

    {/* <!-- CTA Links --> */}
    { !!notExpired && <div class="order-first sticky top-0 z-20">
      <div class="absolute bg-white border-b top-0 left-0 right-0 p-4 w-full shadow-lg transition-all ease-in-out duration-200 opacity-0 -translate-y-full" style={`${ showStickyCta ? "opacity:100%; transform:translateY(0);" : "" }`}>
        <CtaButtons onPledge={onPledge} onShare={onShare} forceRow={true}></CtaButtons>
      </div>
    </div> }

    { !hideModal && <div id="modal" class="overflow-y-auto fixed inset-0 z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-center justify-center min-h-full px-4 pt-4 pb-20 text-center sm:p-0">
        <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" aria-hidden="true"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"></span>
        <div class="inline-block p-5 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-2xl lg:p-16 sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
          <div>
            <div class="mt-3 text-left sm:mt-5">
              <h1 class="mb-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600">Let's make sure you backup your key!</h1>
              <p class="mx-auto text-base leading-relaxed text-gray-500">This is a <em>non-custodial web application</em> so keep transaction amounts fairly low.</p>
              <p class="mx-auto my-2 text-base leading-relazed text-gray-500">Auto-refunds are not supported at this time.</p>
            </div>
          </div>
          <div class="mt-6 sm:flex">
            <div class="mt-3 rounded-lg sm:mt-0">
              <button class="items-center block px-10 py-3.5 text-base font-medium text-center text-blue-600 transition duration-500 ease-in-out transform border-2 border-white shadow-md rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" onClick={(e) => { e.preventDefault(); setHideModal(true); }}><span>Later</span></button>
            </div>
            <div class="mt-3 rounded-lg sm:mt-0 sm:ml-3">
              <button class="items-center block px-10 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-blue-600 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"><span>Reveal seed</span></button>
            </div>
          </div>
        </div>
      </div>
    </div> }
  </>
}