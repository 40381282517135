import { useState, useEffect, useContext } from "preact/hooks"
import { createContext } from 'preact/compat';

export const FundraiserContext = createContext(null);
export const useFundraiser = () => useContext(FundraiserContext);

export function WithFundraiser(children) {
  return FundraiserProvider({ children });
};

export function FundraiserProvider({ children, campaign }) {

  return <FundraiserContext.Provider value={campaign}>  
    {children}
  </FundraiserContext.Provider>
}