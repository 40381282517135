import { useQuery, useQueryClient } from 'react-query'
import { getFrozenUtxos } from "../../utils/frozen-coins.js"
import { useWallet } from '../withWallet.js';

import { blockchainQueryKeys } from '@ipfs-flipstarter/utils/queries/blockchain.js';
import { parseAddressFromOutputScript } from "@ipfs-flipstarter/utils/wallet/index.js";
import { getFirstSeenAddress, setFirstSeenAddress } from '../../utils/firstSeenAddress.js';

export const walletQueryKeys = {
  all: () => ['wallet'],
  unspent: (scriptHash) => ['wallet', 'unspent', scriptHash],
}

export default function useWalletUtxos() {
  const { scriptHash } = useWallet();
  const queryClient = useQueryClient();

  const walletBalanceQueryKey = walletQueryKeys.unspent(scriptHash);
  
  const walletUtxosQuery = useQuery({
    queryKey: walletBalanceQueryKey,
    queryFn: async () => {

      const [utxos, frozenUtxos, firstSeenAddress] = await Promise.all([
        queryClient.fetchQuery(blockchainQueryKeys.utxos(scriptHash), {
          enabled: !!scriptHash,
          staleTime: 0,
        }),
        getFrozenUtxos().catch(() => { return []; }),
        getFirstSeenAddress()
      ]);

      let previousAddress = "";
      if (!firstSeenAddress && utxos.length) {
        
        const firstUtxo = utxos[0];
        const firstTransaction = await Promise.resolve(queryClient.fetchQuery(blockchainQueryKeys.transaction(firstUtxo.txHash), {
          enabled: !!scriptHash,
          staleTime: 0,
        })).catch(() => {});

        let address;
        const found = firstTransaction.ins.find((vin) => {
          address = parseAddressFromOutputScript(vin.script);
          if (address){
            return true;
          }
        });

        if (found) {
          await setFirstSeenAddress(address);
          previousAddress = address;
        }
      }

      const availableUtxos = utxos.map(utxo => ({
        ...utxo,
        isLocked: frozenUtxos.indexOf(utxo.txHash + ":" + utxo.txIndex) !== -1
      }));
      
      return { utxos: availableUtxos, previousAddress: firstSeenAddress };
    },
    enabled: !!scriptHash,
    staleTime: 3000,
    refetchInterval: 3000,
    placeholderData: []
  });

  return walletUtxosQuery;
}