const { notificationFromTransaction, contributionFromTransaction, validateContribution } = require("../utils/flipstarter.js");
const { getAddressScriptHash } = require("../wallet/index.js");
const { feesFor } = require("../wallet/index.js");
const { commitmentFromElectronCash } = require("../utils/flipstarter.js");
const { blockchainQueryKeys } = require("./blockchain.js");
const { encode:cborgencode } = require("cborg");
const { verifyMessage } = require("../wallet/index.js");
const { toString:uint8arrayToString } = require("uint8arrays/to-string")
const { concat:uint8arrayConcat } = require("uint8arrays/concat")
const all = require("it-all");

const contributionQueryKeys = {
  all: () => ['contributions'],
  list: (recipients) => ['contributions', 'list', recipients],
  validateElectronContribution: (recipients, contribution) => ['contributions', 'validateElectronContribution', recipients, contribution],
  parseNotification: (recipients, txHash) => ['contributions', 'parseNotification', recipients, txHash],
  fetchApplicationData: (contribution) => ['contributions', 'fetchApplicationData', contribution]
}

function registerContributionQueries(queryClient, { createIpfs, defaultGatewayUrl } = {}) {
  queryClient.setQueryDefaults(contributionQueryKeys.all(), {
    queryFn: ({ queryKey }) => {
      const [, scope, ...args] = queryKey;

      if (scope === 'validateElectronContribution') {
        const [recipients, electronContribution] = args;
        return validateElectronContribution(queryClient, recipients, electronContribution);
      }

      if (scope === 'list') {
        const [recipients] = args;
        return getContributions(queryClient, recipients);
      }

      if (scope === 'parseNotification') {
        const [recipients, notificationTxHash] = args;
        return parseNotification(queryClient, recipients, notificationTxHash);
      }

      if (scope === 'fetchApplicationData') {
        const [contribution] = args;
        return fetchApplicationData(createIpfs, defaultGatewayUrl, contribution);
      }
    }
  })
}

async function fetchApplicationData(createIpfs, defaultGatewayUrl, contribution) {

  if (!contribution?.cid) return;

  try {
    
    if (defaultGatewayUrl) {

      const remoteFetchResult = await fetch(defaultGatewayUrl + "/ipfs/" + contribution.cid + "/");
      
      const commitmentData = await remoteFetchResult.json();
      
      if (!commitmentData?.applicationDataSignature) return;

      const message = Buffer.from(cborgencode(JSON.stringify(commitmentData.applicationData))).toString("base64")
      
      if(verifyMessage(contribution.address, commitmentData.applicationDataSignature, message)) {
        return commitmentData.applicationData;
      }
    }

  } catch(err) {
    console.log("Error fetching contribution from remote", err);
  }

  try {
    
    const ipfs = await createIpfs;
    
    if (ipfs) {
      const commitmentData = JSON.parse(uint8arrayToString(uint8arrayConcat(await all(ipfs.cat(contribution.cid, { 
        timeout: process.env.NODE_ENV === 'development' ? 20000 : 5000 
      })))));
      
      if (!commitmentData?.applicationDataSignature) return;

      const message = Buffer.from(cborgencode(JSON.stringify(commitmentData.applicationData))).toString("base64")
      
      if(verifyMessage(contribution.address, commitmentData.applicationDataSignature, message)) {
        return commitmentData.applicationData;
      }
    }

  } catch (err) {
    throw new Error("Unable to fetch application data");
  }
}

async function parseNotification(queryClient, recipients, txHash) {

  const notificationTransaction = await queryClient.fetchQuery(blockchainQueryKeys.transaction(txHash));
  const { commitments, isFullfillment } = notificationFromTransaction(notificationTransaction, recipients);
  
  //Technically, fullfillment inputs are contributions (and would be merged with on-chain contribution info later...) but not if an offline, so fetch for all.
  const contributions = (await Promise.all(commitments.map(async (commitment) => {
    
    const committedTransaction = await queryClient.fetchQuery(blockchainQueryKeys.transaction(commitment.txHash));
    const contributionData = contributionFromTransaction(committedTransaction, commitment.txIndex);
    const contribution = { ...commitment, ...contributionData };

    if (validateContribution(contribution, recipients)) {
      return contribution;
    }

  }))).filter(Boolean);
  
  return { isFullfillment, txHash, contributions }
}

async function getContributions(queryClient, recipients = []) {

  //Return a cached version of this particular query if possible, so not always getting fresh transactions
  const notificationAddress = recipients?.[0]?.address;
  //TODO God willing: add second param for p2pk address (may do in other places like recieving coins to this address)
  const notificationScriptHash = notificationAddress && getAddressScriptHash(notificationAddress);
  const notificationTransactionHashes = notificationScriptHash ? await queryClient.fetchQuery(blockchainQueryKeys.transactionHistory(notificationScriptHash), {
    //Whenever requesting get latest
    staleTime: 0
  }) : [];
  
  const notifications = await Promise.all(notificationTransactionHashes.map(async (notificationTransactionHash) => {
    const notification = await queryClient.fetchQuery(contributionQueryKeys.parseNotification(recipients, notificationTransactionHash));
    return notification;
  }));

  const requestedAmount = recipients?.reduce((s, r) => s += r.satoshis, 0) || 0;
  const { contributions, fullfillmentHashes } = await getSortedContributionInfo(queryClient, notifications);

  const totalRaised = (fullfillmentHashes.length * requestedAmount) + contributions.reduce((sum, contribution) => {
    return sum + (contribution.fullfillment ? 0 : contribution.satoshis);
  }, 0);
  
  const isFullfilled = !!fullfillmentHashes.length;
  const fullfillmentFees = isFullfilled ? 0 : getFullfillmentFees(contributions, requestedAmount, recipients?.length || 0);

  return { totalRaised, contributions, fullfillmentFees, isFullfilled };
}

async function validateElectronContribution(queryClient, recipients, electronContribution) {
  try {
    const commitment = commitmentFromElectronCash(electronContribution);
      
    if (null) throw "Parsed commitment is not properly structured."
    
    const committedTransaction = await queryClient.fetchQuery(blockchainQueryKeys.transaction(commitment.txHash));
    const commitmentData = contributionFromTransaction(committedTransaction, commitment.txIndex)
    const contribution = { ...commitment, ...commitmentData }

    if (validateContribution(contribution, recipients)) {
      return contribution;
    }
  
  } catch (err) {
    
    return null;
  }
}

async function getSortedContributionInfo(queryClient, notifications) {
  //For these, we always check utxos 
  let allContributions = {};
  let fullfillmentHashes = [];

  //Iterate once to get all fullfillments
  notifications.forEach(({ isFullfillment, txHash, contributions }) => {
    if (isFullfillment) {
      fullfillmentHashes.push(txHash);
      contributions.forEach((contribution) => {
        const id = contribution.txHash + ":" + contribution.txIndex;
        allContributions[id] = contribution;
      })
    }
  });

  (await Promise.all(notifications.map(async ({ isFullfillment, txHash, contributions }) => {
    if (!isFullfillment) {
      
      return Promise.all(contributions.map(async (contribution) => {
        
        const id = contribution.txHash + ":" + contribution.txIndex;
        const existingContribution = allContributions[id];

        //Skip checking utxo if it's a fullfillment (unless we can force cache to stay unlike non-fullfillments)
        if (existingContribution) {
          allContributions[id] = { ...existingContribution, ...contribution }
        } else {
          
          //Check the status of non-fullfillment commitments.
          const result = await queryClient.fetchQuery(blockchainQueryKeys.utxos(contribution.scriptHash), {
            staleTime: 0
          });
          const isUnspent = !!result.find((utxo) => utxo.txHash === contribution.txHash && utxo.txIndex === contribution.txIndex);
          
          //Only add unspent utxos
          if (isUnspent) {
            allContributions[id] = contribution;
          }
        }
      }));
    }
  })));

  const contributions = Object.values(allContributions).sort((a,b) => b.satoshis - a.satoshis);
  return { contributions, fullfillmentHashes }
}

function getFullfillmentFees(contributions, requestedAmount = 0, outputNum) {
  if (requestedAmount === 0) return 0;
  
  let fullfillmentFees = 0;

  //After ordering, get the fees needed to complete the campaign
      
  //Only keep track of enough utxos to complete the campaign + fees
  let total = 0;
  let count = 0;
  let canFullfill = false;
  for (let i = 0; i < contributions.length; i++) {
    const contribution = contributions[i];

    count += 1;
    total += contribution.satoshis;
    
    //Now that total (including current) took it over the requested amount
    if (total >= requestedAmount) {
      //Check the fees to move these utxos
      const _fees = feesFor(i + 1, outputNum);

      //If total covers the fees, no fees necessary.
      if (total >= requestedAmount + _fees) {
        canFullfill = true;
        break;
      }
    }
  }

  //If cant fullfill, get the fees for count so far + 1 more
  if (!canFullfill) {
    fullfillmentFees = feesFor(count + 1, outputNum);
  }

  return fullfillmentFees;
}

module.exports = { contributionQueryKeys, registerContributionQueries }
