import {render} from "react"
import { get, set } from 'idb-keyval'
import { createRecoveryPhrase } from '@ipfs-flipstarter/utils/wallet/index.js'

import { WalletProvider } from "./components/withWallet.js"
import { ElectrumProvider } from "./components/withElectrum.js"
import { IpfsProvider } from "./components/withIpfs.js"
import { FundraiserProvider } from "./components/withFundraiser.js"

import createIpfs from "./utils/createIpfs.js"
import createElectrum from "@ipfs-flipstarter/utils/network/electrum.js"

import App from "./components/app.js"
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { persistQueryClient } from 'react-query/es/persistQueryClient-experimental/index.js'
import { createWebStoragePersistor } from 'react-query/es/createWebStoragePersistor-experimental/index.js'

const electrum = createElectrum(process.env.ELECTRUM_SERVERS);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: Infinity
    },
  },
})

import { registerContributionQueries, contributionQueryKeys } from "@ipfs-flipstarter/utils/queries/contributions.js"
import { registerBlockchainQueries } from "@ipfs-flipstarter/utils/queries/blockchain.js"

registerContributionQueries(queryClient, { createIpfs, defaultGatewayUrl: process.env.DEFAULT_GATEWAY_URL });
registerBlockchainQueries(queryClient, electrum);

window.queryClient = queryClient;
window.contributionQueryKeys = contributionQueryKeys;

async function getSeedPhrase() {
  
  const base64SeedPhrase = await get("WALLET");
  
  if (base64SeedPhrase) {
    return atob(base64SeedPhrase);
  } else {
    
    const seedPhrase = createRecoveryPhrase();
    await set("WALLET", btoa(seedPhrase));
    return seedPhrase;
  }
}

const minimumLoading = new Promise((resolve) => setTimeout(resolve, 2000));;

async function init() {
  const campaign = await fetch('campaign.json').then(response => response.json());
  const seedPhrase = await getSeedPhrase();

  window.recipients = campaign.recipients;
  
  const localStoragePersistor = createWebStoragePersistor({storage: window.localStorage});
  
  persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
  })
  
  await render(
    <QueryClientProvider client={queryClient}>
      <IpfsProvider createIpfs={createIpfs}>
        <ElectrumProvider electrum={electrum}>
          <WalletProvider seedPhrase={seedPhrase}>
            <FundraiserProvider campaign={campaign}>
              <App campaign={campaign} seedPhrase={seedPhrase}></App>
            </FundraiserProvider>
          </WalletProvider>
        </ElectrumProvider>
      </IpfsProvider>
    </QueryClientProvider>,
    document.getElementById("app")
  )

  await minimumLoading;

  setTimeout(() => {
    const loader = document.getElementById("main-loader");
    if (loader) loader.remove()
    
    document.body.classList.remove("loading");
  }, 1000);
}

init();