import { useState, useRef, useEffect } from "preact/hooks";
import FloatingLabel from "../../../inputs/floating-label.js";
import PrimaryButton from "../../../inputs/primary-button.js";
import SecondaryButton from "../../../inputs/secondary-button.js";
import Modal from "../../../modal.js";

import useCopy from "../../../hooks/useCopy.js";

import { encode as cborgencode } from "cborg";

import { createExternalWalletPayload, createOpReturnTransaction } from "@ipfs-flipstarter/utils/utils/flipstarter.js";

import { verifyMessage } from "@ipfs-flipstarter/utils/wallet/index.js";
import { useValidateExternalCommitmentQuery } from "../../../queries/validateExternalCommitment.js";
import { useIpfs } from "../../../withIpfs.js";
import { useQueryClient } from "react-query";
import { contributionQueryKeys } from "@ipfs-flipstarter/utils/queries/contributions.js";
import { route } from "preact-router";

export default function ElectronModal({ donationAmount, recipients, expires, onClose:userOnClose }) {
  const { ipfs } = useIpfs();
  const [step, setStep] = useState(0);
  const [alias, setAlias] = useState(false);
  const [comment, setComment] = useState(false);
  const [disableNextStep, setDisableNextStep] = useState(true);  
  const [userCommitmentPayload, setUserCommitmentPayload] = useState(null);

  function onCommitmentResultChange(e) {
    setUserCommitmentPayload(e.target.value);
  }

  const { isLoading:showResultLoading, error:resultException, data:electronCommitment } = useValidateExternalCommitmentQuery(userCommitmentPayload, recipients);
  const showResultError = userCommitmentPayload && (!showResultLoading && !electronCommitment) || resultException;

  useEffect(() => {
    setDisableNextStep(showResultError);
  }, [showResultError])
  
  const externalWalletPayload = createExternalWalletPayload(donationAmount, recipients, expires);
  const [copyExternalWalletPayload, copyExternalSuccess] = useCopy(externalWalletPayload);

  const resultPayloadAreaRef = useRef();

  const dataMessage = Buffer.from(cborgencode(JSON.stringify({ comment, alias }))).toString("base64");
  const [copyDataMessage, copyDataMessageSuccess] = useCopy(dataMessage);
  
  const address = electronCommitment && electronCommitment.address;
  const [copyAddress, copyAddressSuccess] = useCopy(address);

  const [dataSignature, setDataSignature] = useState(null);
  const [showSignatureError, setShowSignatureError] = useState(false);
  const [bip21Uri, setBip21Uri] = useState(null);
  
  const queryClient = useQueryClient();
  async function onClose() {
    if (step === steps.length - 1) {
      await queryClient.cancelQueries(contributionQueryKeys.list());
      await queryClient.invalidateQueries(contributionQueryKeys.list());
      setTimeout(() => route('/'), 2000);
    }
    
    userOnClose?.();
  }

  useEffect(async () => {
    if (step !== steps.length - 1) return;

    const opReturnRaw = await createOpReturnTransaction(ipfs,  process.env.DEFAULT_API_URL, {
      ...electronCommitment,
      data: {
        comment,
        alias,
      },
      dataSignature
    }, recipients);

    const bip21Uri = recipients[0].address + "?amount=.00000546&op_return_raw=" + opReturnRaw;
    setBip21Uri(bip21Uri);

  }, [step])

  const [copyBip21Uri, copyBip21UriSuccess] = useCopy(bip21Uri);

  const steps = [
    {
      heading: "First, copy the following data to the EC Flipstarter Plugin...",
      body: <>
        <div class="flex relative border rounded m-1 text-gray-500">
          <textarea readonly onClick={(e) => {
            e.target.focus();
            e.target.select();
            copyExternalWalletPayload();
          }} class="h-24 w-full border rounded p-4 overflow-hidden" value={externalWalletPayload}>
          </textarea>
          <FloatingLabel for="comment" className="absolute top-1 left-4 -translate-y-3.5 bg-white text-black">Copy to plugin</FloatingLabel>
          <div className="absolute inset-y-1/2 right-5 bg-gray-400 p-4 text-center inline-flex justify-center items-center rounded text-white -translate-y-1/2 shadow-xl cursor-pointer hover:bg-gray-500" onClick={(e) => {
            copyExternalWalletPayload();
          }}>{ copyExternalSuccess ? "Copied!" : "Copy" }</div>
        </div>
        <div class="flex relative border rounded m-1 text-gray-500 mt-4">
          <div class="h-24 w-full border rounded py-4 pb-0">
            <textarea ref={resultPayloadAreaRef} class="w-full h-full px-4 outline-none overflow-hidden" onChange={onCommitmentResultChange}>
            </textarea>
            <FloatingLabel for="comment" className="absolute top-1 left-4 -translate-y-3.5 bg-white text-black">Paste the result</FloatingLabel>
          </div>
        </div>
        {!!showResultError && !showResultLoading ? <div className={`text-red-600 pl-4 pt-2 font-bold`}>We had an error decoding this.</div> : <></> }
        { showResultLoading ? <div className={`pl-4 pt-2 font-bold text-green-500`}>Validating...</div> : <></> }
      </>
    },
    {
      heading: "Optionally, add a mesesage to be displayed on the campaign.",
      primaryButton: !comment && !alias ? "Skip" : "Next",
      body: <>
        <fieldset className="flex flex-col gap-2 py-3">
          <div class="flex relative border rounded m-1 text-gray-500 pt-6 px-4 pb-2 ">
            <input class="w-full peer text-black outline-0" id="alias" type="text" name="alias" placeholder="&nbsp;" onChange={(e) => setAlias(e.target.value)}></input>
            <FloatingLabel for="alias" className="absolute top-1 left-4 translate-y-3 bg-transparent">Alias</FloatingLabel>
          </div>
          <div class="flex relative border rounded m-1 text-gray-500 pt-6 px-4 pb-2 ">
            <input class="w-full peer text-black outline-0" id="comment" type="text" name="comment" placeholder="&nbsp;" onChange={(e) => setComment(e.target.value)}></input>
            <FloatingLabel for="comment" className="absolute top-1 left-4 translate-y-3 bg-transparent">Comment</FloatingLabel>
          </div>
        </fieldset>
      </>
    },
    {
      heading: "Sign your mesesage.",
      primaryButton: !dataSignature ? "Skip" : "Next",
      body: <>
        <div class="mx-auto text-base leading-relaxed text-gray-500">Paste in <b>Tools &gt; Sign/Verify Message</b> and click <b>Sign</b>. Copy the resulting signature here:</div>
        <fieldset className="flex flex-col gap-2 py-3">                 
          <div class="flex relative border rounded m-1 text-gray-500 pt-6 px-4 pb-2 ">
            <input data-testid="electronCashSignDataMessage" onClick={(e) => {
              e.target.focus();
              e.target.select();
              copyDataMessage();
            }} readonly value={dataMessage} class="peer w-full outline-none text-black"></input>
            <FloatingLabel for="address" className="absolute top-1 left-4 translate-y-3 bg-transparent">Message</FloatingLabel>
            <div className="absolute inset-y-1/2 right-5 bg-gray-400 p-4 text-center inline-flex justify-center items-center rounded text-white -translate-y-1/2 shadow-xl cursor-pointer hover:bg-gray-500" onClick={(e) => {
              copyDataMessage();
            }}>{ copyDataMessageSuccess ? "Copied!" : "Copy" }</div>
          </div>
          
          <div class="flex relative border rounded m-1 text-gray-500 pt-6 px-4 pb-2 ">
            <input data-testid="electronCashAddress" value={address} readonly class="peer w-full text-black outline-0" id="address" type="text" name="address" placeholder="&nbsp;" onClick={(e) => {
              e.target.focus();
              e.target.select();
              copyAddress();
            }}></input>
            <FloatingLabel for="address" className="absolute top-1 left-4 translate-y-3 bg-transparent">Address</FloatingLabel>
            <div className="absolute inset-y-1/2 right-5 bg-gray-400 p-4 text-center inline-flex justify-center items-center rounded text-white -translate-y-1/2 shadow-xl cursor-pointer hover:bg-gray-500" onClick={(e) => {
              copyAddress();
            }}>{ copyAddressSuccess ? "Copied!" : "Copy" }</div>
          </div>


          <div class="mx-auto text-base leading-relaxed text-gray-500">Copy the resulting signature here:</div>
          <textarea class="flex relative border rounded m-1 text-gray-500 p-4" onChange={async (e) => {
            setDataSignature(null);
            const value = e.target.value;
            let verified = false;
            try {
              const message = Buffer.from(cborgencode(JSON.stringify({ comment, alias }))).toString("base64")
              verified = verifyMessage(electronCommitment.address, value, message);
            } catch (err) {}
            
            setDisableNextStep(!verified);
            setShowSignatureError(!verified);
            if (verified) setDataSignature(value);

          }}></textarea>
            <div className={`text-red-600 pl-4 font-bold ${!showSignatureError ? 'invisible' : ''}`}>This doesn't look like a valid signature, please try again.</div>
        </fieldset>
      </>
    },
    {
      heading: "Finally, broadcast your contribution!",
      subheading: <>Copy the following data and paste into Electron Cash's <b>Send</b> Tab</>,
      secondaryButton: "Done",
      body: <div class="flex relative border rounded m-1 text-gray-500 pt-6 px-4 pb-2 mt-6">
        <input data-testid="electronCashAddress" value={bip21Uri} readonly class="peer w-full text-black outline-0" id="address" type="text" name="address" placeholder="&nbsp;" onClick={(e) => {
          e.target.focus();
          e.target.select();
          copyBip21Uri();
        }}></input>
        <FloatingLabel for="address" className="absolute top-1 left-4 translate-y-3 bg-transparent">Bip 21</FloatingLabel>
        <div className="h-8 flex gap-2 absolute inset-y-1/2 right-4 -translate-y-1/2">
          <div className="h-full bg-gray-400 p-4 text-center inline-flex justify-center items-center rounded text-white shadow-xl cursor-pointer hover:bg-gray-500" onClick={(e) => {
            copyBip21Uri();
          }}>{ copyBip21UriSuccess ? "Copied!" : "Copy" }</div>
          <a href={bip21Uri} target="_blank" className="h-full bg-gray-400 p-4 text-center inline-flex justify-center items-center rounded text-white shadow-xl cursor-pointer hover:bg-gray-500">Open</a>
        </div>
      </div>
    }
  ]

  const stepInfo = steps[step];

  function onNextStep() {
    const nextStep = steps[step + 1];
    if (nextStep && nextStep.disablePrimaryButton) setDisableNextStep(true);
    if (step === 1 && !comment && !alias) return setStep(3);
    setStep(step + 1)
  }

  const footer = step === steps.length - 1 ? <div class="flex justify-end">
    <SecondaryButton onClick={onClose}>Done</SecondaryButton>
  </div> : <div class="flex justify-between">
    <PrimaryButton disabled={disableNextStep} onClick={onNextStep}>{ stepInfo.primaryButton || "Next" }</PrimaryButton>
    <SecondaryButton onClick={onClose}>{ stepInfo.secondaryButton || "Cancel" }</SecondaryButton>
  </div>

  return <Modal heading={stepInfo.heading} subheading={stepInfo.subheading} footer={footer}>
    { stepInfo.body }
  </Modal>
}