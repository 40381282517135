const validCommitmentSignature = require("../validation/validCommitmentSignature.js");
const { parseNotificationTransaction, getScriptHash } = require("../flipstarter.js");
const { getAddressFromLockscript } = require("../wallet/index.js");
const { hashCommitmentData } = require("./flipstarterTransactions.js");
const { Buffer } = require("buffer");

module.exports = {
  notificationFromTransaction,
  contributionFromTransaction,
  commitmentFromElectronCash,
  validateContribution,
  createExternalWalletPayload,
  createOpReturnTransaction
}

function notificationFromTransaction(transaction, recipients) {
  return parseNotificationTransaction(transaction, recipients);
}

function contributionFromTransaction(transaction, txIndex) {
  /** @type {{value:number, script:Uint8Array}} */
  const vout = transaction.outs[txIndex];

  const lockingScript = Buffer.from(vout.script).toString('hex');
  
  return {
    lockingScript,
    address: getAddressFromLockscript(lockingScript),
    scriptHash: getScriptHash(vout.script),
    satoshis: vout.value,
    seqNum: 0xffffffff,
  }
}

function commitmentFromElectronCash(base64text) {
    
  try {
    // Attempt to decode the base64 contribution.
    const commitmentObject = JSON.parse(Buffer.from(base64text, 'base64').toString('utf8'));

    const { 
      previous_output_transaction_hash:txHash, 
      previous_output_index:txIndex,
      sequence_number:seqNum,
      unlocking_script:unlockingScript
    } = commitmentObject.inputs[0];

    return { txHash, txIndex, seqNum, unlockingScript };

  } catch (err) {
    
    return null;
  }
}

function validateContribution(commitment, recipients) {
  if (!recipients || !recipients.length) {
    throw "Cannot validate contribution without recipients!"
  }
  
  let isValid = false;
  
  try {
    isValid = validCommitmentSignature(recipients, commitment);
  } catch (err) {
    isValid = false;
  }
  
  return isValid;
}

function getDefaultExpiration() {
  // Add a 30 day expiration for custom campaign.json's
  const expires = new Date();
  expires.setDate(expires.getDate() + 30);
  return Math.floor(expires.getTime() / 1000)
}

function createExternalWalletPayload(satoshis, recipients, expires) {

  //TODO God willing: wait for EC to support alias/comment (or screen for correct signature first or show notification to user instead of error)
  const alias = "";
  const comment = "";

  const outputs = recipients.map((recipient) => {
    const outputValue = recipient.satoshis;
    const outputAddress = recipient.address;

    // Add the recipients outputs to the request.
    return { value: outputValue, address: outputAddress };
  });

  const expiresUnixTimestamp = expires || getDefaultExpiration();

  // Assemble an assurance request template.
  const template = JSON.stringify({
    outputs,
    data: {
      alias,
      comment,
    },
    donation: {
      amount: Number(satoshis),
    },
    expires: expiresUnixTimestamp
  });

  return Buffer.from(template, "utf8").toString('base64');
}

async function createOpReturnTransaction(ipfs, defaultApiUrl, commitment, recipients) {  
  let commitmentCid;

  try {
    //Hash the commitment information sent to IPFS.
    commitmentCid = (commitment.data?.alias || commitment.data?.comment) && (await hashCommitmentData(ipfs, defaultApiUrl, commitment, recipients));
  } catch (err) {
    debugger;
  }

  //Serializes commitment and posts to blockchain using walletService.makeNotification (op_return)
  const txHashBuffer = Buffer.from(commitment.txHash, "hex");
  
  const outputIndex = Buffer.alloc(4);
  outputIndex.writeUInt32LE(commitment.txIndex);
  
  const sequenceNumber = Buffer.alloc(4);
  sequenceNumber.writeUInt32LE(commitment.seqNum);

  const unlockingScriptBuffer = Buffer.from(commitment.unlockingScript, "hex");
  
  //V0 is 34 bytes
  //TODO God willing: now that everything is verified, if it has an alias/comment then add, iA:
  const cidBytes = commitmentCid?.bytes || commitmentCid || Buffer.alloc(34);  
  
  //32 bytes, 32 bytes, 4 bytes, 4 bytes, 34 bytes, rest is unlocking.
  const outputData = Buffer.concat([
    txHashBuffer, 
    outputIndex, 
    sequenceNumber, 
    cidBytes, 
    unlockingScriptBuffer
  ]);
  
  if (outputData.byteLength > 220) {
    throw new Error("Unable to fit in one transaction");
  } 
  
  const opReturn = "4c" + //OP_PUSHDATA1
    outputData.byteLength.toString(16) + //Byte length
    outputData.toString("hex");

  return opReturn;
}