import { useState, useEffect } from "preact/hooks";
import prettyPrintSats from "../../../../utils/prettyPrintSats.js";
import DonationAmount from "../../../inputs/donation-amount.js";
import FloatingLabel from "../../../inputs/floating-label.js";
import PrimaryButton from "../../../inputs/primary-button.js";
import { MIN_SATOSHIS } from "../../../../utils/bchConstants.js";

function getDonationAmountError(donationAmount, max) {
  if (donationAmount > max) {
    const [text, denomination] = prettyPrintSats(max);
    return { error: `This donation exceeds what the campaign needs (${text} ${denomination})` }
  } else if (!donationAmount || isNaN(donationAmount)) {
    return { error: "Please enter a donation amount" }
  } else if (donationAmount < MIN_SATOSHIS) {
    return { error: "Minimum donation amount is 546 SATS" }
  } else {
    return { valid: true }
  }
}

export default function DonationAmountSection({ walletBalance, defaultDonationAmount, campaignRequestingAmount, fullfillmentFees = 0, campaignBalance, currentContributionAmount, onContinue, onDonationAmountChange }) {
    
  const [donationAmount, setDonationAmount] = useState(defaultDonationAmount);

  const [donationInputType, setDonationInputType] = useState("default");
  const [donationAmountSettled, setDonationAmountSettled] = useState(false);

  const [showDonationError, setShowDonationError] = useState(false);
  const [donationAmountError, setDonationAmountError] = useState(null);
  const [clickedOnce, setClickedOnce] = useState(false);

  const goal = campaignRequestingAmount + fullfillmentFees;
  let max = 0;
  if (currentContributionAmount < goal) {
    const remainingAmount = goal - currentContributionAmount;
    max = remainingAmount < MIN_SATOSHIS ? MIN_SATOSHIS : remainingAmount
  }

  useEffect(() => {
    if (showDonationError) {
      const { error } = getDonationAmountError(donationAmount, max);
      setDonationAmountError(error); 
    }
  }, [donationAmount, showDonationError, max])

  function _onDonationAmountBlur() {
    setShowDonationError(true)
  }

  function _onDonationAmountChange(value) {
    const val = Number(value);

    if (donationInputType === "slider" && (val < 0 || val > MIN_SATOSHIS)) {
      setShowDonationError(true);
    }
    const donationAmount = isNaN(val) ? 0 : val;
    setDonationAmount(donationAmount);
    onDonationAmountChange && onDonationAmountChange(donationAmount)
  }

  function _onContinueButtonClick(e) {
    //Let's continue if they already see the error
    if (clickedOnce || getDonationAmountError(donationAmount, max).valid) {
      setDonationAmountSettled(true);
      onContinue && onContinue()
    }

    setShowDonationError(true);
    setClickedOnce(true);
  }

  return<fieldset class="mb-6">
    <div class="mb-6">
      <div class="mb-4">
        <label class="font-bold text-gray-800" for="donationAmount">Enter your donation</label>
        { donationInputType === "default" && <a onClick={() => setDonationInputType("slider")} class="text-blue-500 cursor-pointer underline text-xs pl-2">with a slider.</a> }
        { donationInputType !== "default" && <a onClick={() => setDonationInputType("default")} class="text-blue-500 cursor-pointer underline text-xs pl-2">back to default.</a> }
      </div>
      <DonationAmount
        defaultDonationAmount={defaultDonationAmount}
        onDonationAmountChanged={_onDonationAmountChange} 
        onBlur={_onDonationAmountBlur}
        current={currentContributionAmount}
        goal={campaignRequestingAmount + fullfillmentFees}
        donationInputType={donationInputType}
        donationAmountError={showDonationError && donationAmountError}
        walletBalance={walletBalance}
      ></DonationAmount>
    </div>
    <div class="mb-6">
      <legend class="font-bold text-gray-800">Tip Interplanetary services</legend>
      <div class="mt-4">
        <span class="text-gray-400">
          Interplanetary Flipstarters is a decentralized platform with 0% fees and relies on the generosity of donors like you to operate this service. <br/> <b>Here's our</b> <a class="text-blue-500 cursor-pointer underline" href={process.env.DEV_TIPS_ADDRESS || "bitcoincash:qpclad9r4zah39du3n55xj3mwdrkeuh0nyx8dqfqut"}>address</a>
        </span>
      </div>
    </div>

    { !donationAmountSettled && <PrimaryButton onClick={_onContinueButtonClick}>Continue</PrimaryButton> }
  </fieldset>
}