import { useQuery } from "react-query";
import { contributionQueryKeys } from "@ipfs-flipstarter/utils/queries/contributions.js";

export const useValidateExternalCommitmentQuery = (userCommitment, recipients) => {

  return useQuery(contributionQueryKeys.validateElectronContribution(recipients, userCommitment), {
    enabled: !!userCommitment,
    staleTime: 5000,
    cacheTime: 10000,
  });
}
