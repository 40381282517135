import { useQueryClient, useMutation } from 'react-query';
import { useElectrum } from "../withElectrum.js";
import { useFundraiser } from "../withFundraiser.js";

import { broadcastTransaction } from "@ipfs-flipstarter/utils/utils/electrum.js";
import { contributionQueryKeys } from '@ipfs-flipstarter/utils/queries/contributions.js';

export default function useBroadcastTransaction() {
  const electrum = useElectrum();
  const queryClient = useQueryClient();
  const { recipients } = useFundraiser();

  const broadcastTransactionMutation = useMutation((txHex) => {
    return broadcastTransaction(electrum, txHex);
  }, {
    onMutate: () => {
    },
    onError: () => {
    },
    onSettled: () => {

    },
    onSuccess: async () => {
      return queryClient.invalidateQueries(contributionQueryKeys.list(recipients))
    }
  });

  return broadcastTransactionMutation.mutateAsync;
}