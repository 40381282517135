import FloatingLabel from "../../../inputs/floating-label.js";
import RadioButton from "../../../inputs/radio-button.js";
import PrimaryButton from "../../../inputs/primary-button.js";
import { useIpfs } from "../../../withIpfs.js";
import { useRef, useEffect, useState } from "preact/hooks";

const today = new Date();
const todayFormatted = today.toISOString().split("T")[0];

export default function PaymentMethodSection({ paymentMethod, onPaymentTypeChanged, onAliasChange, onCommentChange, showRefundDate, onRefundDateChange }) {
  const { error: ipfsError } = useIpfs();

  const [isRefundDirty, setRefundDirty] = useState(false);
  const electronRefundRef = useRef();
  const walletRefundRef = useRef();

  function _onRefundDateChanged(e) {
    setRefundDirty(!!e.target?.value);
    onRefundDateChange(e);
  }

  useEffect(() => {
    const refundRef = paymentMethod === "wallet" ? walletRefundRef : paymentMethod === "electroncash" ? electronRefundRef : null;
    setRefundDirty(!!refundRef?.current?.value);
  }, [walletRefundRef.current, electronRefundRef.current, paymentMethod])

  return <>
    <div class="mb-6">
      <legend class="font-bold text-gray-800">Payment method</legend>
      <div class="mt-4">
        <ul class="border rounded list-none text-xl tracking-wide">
          <li class="border-b border-b-1 p-4">
            <div class="flex items-center px-4 py-3">
              <RadioButton  name="paymentMethod" id="wallet" value="wallet" checked={paymentMethod==="wallet"} onChange={onPaymentTypeChanged}></RadioButton>
              <label class="pl-4 w-full cursor-pointer" for="wallet">
                <div></div>
                <span>Integrated wallet</span>
              </label>
            </div>
            {
              paymentMethod==="wallet" && <fieldset className="flex flex-col gap-2 py-3">
                <div class="group group-hover:bg-gray-300 flex relative border rounded m-1 text-gray-500 pt-6 px-4 pb-2 ">
                  <input class="w-full peer text-black outline-0" id="alias" type="text" name="alias" placeholder="&nbsp;" onChange={onAliasChange}></input>
                  <FloatingLabel for="alias" className="absolute top-1 left-4 translate-y-3 bg-transparent">Alias</FloatingLabel>
                </div>
                <div class="group group-hover:bg-gray-300 flex relative border rounded m-1 text-gray-500 pt-6 px-4 pb-2 ">
                  <input class="w-full peer text-black outline-0" id="comment" type="text" name="comment" placeholder="&nbsp;" onChange={onCommentChange}></input>
                  <FloatingLabel for="comment" className="absolute top-1 left-4 translate-y-3 bg-transparent">Comment</FloatingLabel>
                </div>
                { showRefundDate && <div class="group group-hover:bg-gray-300 flex relative border rounded m-1 text-gray-500 pt-6 px-4 pb-2 ">
                  <input ref={walletRefundRef} min={todayFormatted} className={`${isRefundDirty ? "text-black" : "text-gray-500"} w-full peer outline-0`} id="refundDate" type="date" name="refundDate" onChange={_onRefundDateChanged}></input>
                  <FloatingLabel for="refundDate" className={`text-black absolute top-1 left-4 translate-y-3 bg-transparent`}>Auto-refund Date</FloatingLabel>
                </div> }
              </fieldset>
            }
          </li>
          <li class="border-b border-b-1 p-4">
            <div class="flex items-center px-4 py-3">
              <RadioButton  name="paymentMethod" id="electroncash" value="electroncash" checked={paymentMethod==="electroncash"} onChange={onPaymentTypeChanged}></RadioButton>
              <label class="pl-4 w-full cursor-pointer" for="electroncash">
                <div></div>
                <span>Electron Cash</span>
              </label>
            </div>
            {
              paymentMethod==="electroncash" && showRefundDate && <fieldset className="flex flex-col gap-2 py-3">
                <div class="group group-hover:bg-gray-300 flex relative border rounded m-1 text-gray-500 pt-6 px-4 pb-2 ">
                  <input ref={electronRefundRef} min={todayFormatted}  className={`${isRefundDirty ? "text-black" : "text-gray-500"} w-full peer outline-0`} id="refundDate" type="date" name="refundDate" onChange={_onRefundDateChanged}></input>
                  <FloatingLabel for="refundDate" className={`text-black absolute top-1 left-4 translate-y-3 bg-transparent`}>Auto-refund Date</FloatingLabel>
                </div>
              </fieldset>
            }
          </li>
        </ul>
      </div>
    </div>
  </> 
}