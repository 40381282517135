import styled from "styled-components"
import { useState, useEffect, useContext, useRef, useMemo } from "preact/hooks";
import prettyPrintSats from "../../../utils/prettyPrintSats.js";
import CheckoutModal from "./modals/checkout-modal.js";
import BackupModal from "./modals/backup-modal.js";
import DonationAmount from "../../inputs/donation-amount.js";
import FloatingLabel from "../../inputs/floating-label.js";
import RadioButton from "../../inputs/radio-button.js";
import PrimaryButton from "../../inputs/primary-button.js";
import SecondaryButton from "../../inputs/secondary-button.js";
import Modal from "../../modal.js";
import ProgressBar from "../landing/donations/progress-bar.js";
import Navigation from "../../navigation.js";
import { Router,route } from "preact-router";
import { SATS_PER_BCH, MIN_SATOSHIS } from "../../../utils/bchConstants.js";
import leftArrowSvg from "../../../../public/img/chevron-left-solid.svg"
import copyToClipboard from "copy-to-clipboard";
import { useWallet } from "../../withWallet.js";
import { useFundraiser } from "../../withFundraiser.js";

import ElectronModal from "./modals/electron-modal.js";
import DonationAmountSection from "./sections/donation-amount-section.js";
import PaymentMethodSection from "./sections/payment-method-section.js";
import { useContributionsQuery } from "../../queries/useContributions.js";
import useWalletUtxos from "../../queries/useWalletBalance.js";

import moment from "moment"

const Grid = styled.div.attrs()`
    display: grid;
    grid-gap: 1rem;
    grid-template-areas:
        "main"
        "sidebar";
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding-bottom: 1rem;
    padding-top: 1rem;
    
    @media screen and (min-width: 1024px) {
      grid-gap: 1.5rem 2rem;
      grid-template-areas: ". main main sidebar .";
      grid-template-columns: 1fr 4fr 4fr 4fr 1fr;
      grid-template-rows: auto;
      padding-bottom: 2rem;
      padding-top: 2rem;
    }
}`

const MainGridItem = styled.div`grid-area: main;`
const SidebarGridItem = styled.div`grid-area: sidebar;`
const todaysDate = new Date();
const oneMonthFromNow = new Date();
oneMonthFromNow.setMonth(todaysDate.getMonth() + 1);

export default function DonationPage({ defaultCurrency = "BCH", defaultDonationAmount }) {
  defaultDonationAmount = defaultDonationAmount ? Number(defaultDonationAmount) : 0;

  const campaign = useFundraiser();
  const { seedPhrase } = useWallet();
  const { data:walletInfo } = useWalletUtxos();

  const { utxos = [] } = walletInfo || {};
  
  const walletBalance = useMemo(() => {
    const availableUtxos = utxos.filter(utxo => !utxo.isLocked);
    const walletBalance = availableUtxos.reduce((sum, utxo) => sum + utxo.satoshis, 0);
    return walletBalance;
  }, utxos);

  //So from now on, wait on the current contribution raised query and refetch regularly.
  const contributionsQuery = useContributionsQuery(campaign?.recipients);
  const { totalRaised:currentContributionAmount = 0, fullfillmentFees = 0 } = contributionsQuery.data || {};
  
  const campaignRequestingAmount = campaign.recipients.reduce((sum, recipient) => sum + recipient.satoshis, 0);

  const campaignBalance = campaignRequestingAmount > currentContributionAmount ? campaignRequestingAmount - currentContributionAmount : 0;
  const organizer = campaign.recipients && campaign.recipients.length ? campaign.recipients[0] : {};
  
  const [donationAmount, setDonationAmount] = useState(defaultDonationAmount);
  const [donationAmountSettled, setDonationAmountSettled] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [disableCheckoutButton, setDisableCheckoutButton] = useState(true);
  const [hideCheckoutModal, setHideCheckoutModal] = useState(true);
  const [hideElectronModal, setHideElectronModal] = useState(true);

  const [alias, setAlias] = useState(false);
  const [comment, setComment] = useState(false);
  const [refundDate, setRefundDate] = useState(oneMonthFromNow);
  const refundTimestamp = refundDate && Math.floor(refundDate.getTime() / 1000);

  const campaignExpirationDate = campaign?.expires && new Date(campaign.expires * 1000);
  const validCampaignExpiration = campaignExpirationDate && campaignExpirationDate.getTime() > todaysDate.getTime();

  const totalAmount = donationAmount;
  const [donationAmountText, donationAmountDenomination] = prettyPrintSats(donationAmount);
  const [totalAmountText, totalAmountDenomination] = prettyPrintSats(totalAmount);

  function onDonationAmountChange(donationAmount) {
    setDonationAmount(donationAmount);
  }


  function onContinue() {
    setDonationAmountSettled(true);
    route(`/donate/${donationAmount}`)
  }

  function onPaymentTypeChanged(e) {
    setPaymentMethod(e.target.value);
    setDisableCheckoutButton(false);
  }

  function onDonateNow(e) {
    if (paymentMethod === "wallet") setHideCheckoutModal(false);
    if (paymentMethod === "electroncash") {
      setComment("");
      setAlias("");
      setRefundDate(oneMonthFromNow);
      setHideElectronModal(false);
    }
    
    route(`/donate/${donationAmount}`)
  }

  function GridSeparator({ padding = 6 }) {
    return <div class={`h-0 w-full border-t border-t-1 border-gray-300 my-${padding}`}></div>
  }

  return <>
    <Navigation></Navigation>
    <Grid className="donate-page px-3 sm:px-4 lg:py-8 lg:px-16" onClick={(e) => e.target.focus()}>
      <MainGridItem className="donate-main">
        <div>
          <button class="hidden lg:block border border-gray-300 rounded px-4 py-1 text-gray-800" onClick={() => route("/")}>
            <div class="flex">
              <div class="text-lg h-4 w-4 p-1 mr-2"><img src={leftArrowSvg}></img></div>
              <div class="font-bold">Return to campaign</div>
            </div>
          </button>

          <div class="flex flex-row gap-4 my-6 lg:my-8">
            <div>
              <div class="bg-gray-200 relative w-40" style="padding-top:59%;">
                <div class="absolute bg-center bg-cover bg-no-repeat inset-0" style={campaign?.image ? `background-image: url(${campaign.image})` : ''}></div>
              </div>
            </div>
            <div class="mb-4">
              <div>You're supporting <b>{campaign.title}</b></div>
              <div class="text-gray-500 mt-1">Your donation will benefit <b>{organizer.name}</b></div>
            </div>
          </div>

          <form onSubmit={e => { e.preventDefault(); }}>
            <DonationAmountSection 
              defaultDonationAmount={defaultDonationAmount}
              campaignRequestingAmount={campaignRequestingAmount} 
              fullfillmentFees={fullfillmentFees} 
              campaignBalance={campaignBalance} 
              currentContributionAmount={currentContributionAmount} 
              walletBalance={walletBalance}
              onContinue={onContinue} 
              onDonationAmountChange={onDonationAmountChange}
            ></DonationAmountSection>

            { donationAmountSettled && <PaymentMethodSection 
                paymentMethod={paymentMethod} 
                onPaymentTypeChanged={onPaymentTypeChanged} 
                onAliasChange={(e) => setAlias(e.target.value)}
                onCommentChange={(e) => setComment(e.target.value)}
                showRefundDate={!validCampaignExpiration}
                onRefundDateChange={(e) => setRefundDate(moment(e.target.value).endOf('day').toDate())}>
            </PaymentMethodSection>}
          </form>

          { donationAmountSettled && <PrimaryButton disabled={disableCheckoutButton} onClick={onDonateNow}>Donate now</PrimaryButton> }
          { !hideCheckoutModal && <CheckoutModal donationAmount={donationAmount} recipients={campaign.recipients} alias={alias} comment={comment} refundTimestamp={campaign.expires || refundTimestamp} onHide={() => setHideCheckoutModal(true)}></CheckoutModal>}
          { !hideElectronModal && <ElectronModal donationAmount={donationAmount} recipients={campaign.recipients} expires={campaign.expires} onClose={() => setHideElectronModal(true)}></ElectronModal>}

        </div>
      </MainGridItem>
    </Grid>
  </>
}